import React, { useState, useEffect } from "react";
import { Layout, Menu, List } from "antd";
import { Link } from "react-router-dom";
import { Divider, Spin } from "antd";
import { Card, Col, Row } from "antd";
import { Typography } from "antd";

import { UserOutlined } from "@ant-design/icons";
import UpdatePatientDetails from "../../Components/patientdetails/updatePatientDetails.component";

import "../appointments/appointments.styles.css";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Title, Text } = Typography;

const PatientDetails = (props) => {
  const [loader, setToggle] = useState(false);
  const [patients, setDetails] = useState([]);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    fetchDetails();
    fetchSubscriptionDetails();
  }, []);

  useEffect(() => {
    params["name"] =
      patients?.patient_first_name + " " + patients?.patient_last_name;
    params["email"] = patients?.patient_email;
    console.log(params);
  }, [patients]);

  let PatientId = props.match.params.id;
  const PatientName = props.location?.param1?.name?.replace("undefined", "");
  const PatientEmail = props.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var locationParams = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };
  var params = {
    // name: `${record.patient_first_name}  ${record.patient_last_name}`,
    // `${record.patient_last_name}`,
    // email: `${record.patient_email}`,
  };

  const fetchDetails = () => {
    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setDetails(result);
        setToggle(true);
      });
  };

  const fetchSubscriptionDetails = () => {
    const url = `https://app02.thbglobal.com/v1/subscription/check/${PatientId}/0`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setSubscription(result.data);
        setToggle(true);
      });
  };

  return (
    <div className="patients-details">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal" style={{ float: "left" }}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["1"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: locationParams ? locationParams : params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: locationParams ? locationParams : params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: locationParams ? locationParams : params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: locationParams ? locationParams : params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: locationParams ? locationParams : params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: locationParams ? locationParams : params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  {/* <Link to={`/medicalreports/${PatientId}`}> */}
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: locationParams ? locationParams : params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 50px" }}>
            {loader ? (
              <>
                <div className="appointments-container">
                  <div className="page-title">
                    <h1>
                      {patients?.patient_first_name +
                        " " +
                        patients?.patient_last_name +
                        " "}
                      {patients?.patient_email?.length > 0
                        ? `| ${patients.patient_email}`
                        : null}
                      {patients?.membership_number?.length > 1
                        ? `| ${patients.membership_number}`
                        : null}
                      {patients.patient_mobile?.length > 1
                        ? `| ${patients.patient_mobile}`
                        : null}
                    </h1>
                  </div>
                  <UpdatePatientDetails
                    id={PatientId}
                    patient_first_name={patients?.patient_first_name}
                    patient_last_name={patients?.patient_last_name}
                    patient_email={patients?.patient_email}
                    patient_gender={patients?.patient_gender}
                    patient_dob={patients?.patient_dob}
                    primary_health_issue={patients?.primary_health_issue}
                    allergies={patients?.allergies}
                    address_line_1={patients?.primary_address_line_one}
                    address_line_2={patients?.primary_address_line_two}
                    patient_city={patients?.patient_city}
                    patient_state={patients?.patient_state}
                    patient_country={patients?.patient_country}
                    emergency_contact_name={patients?.emergency_contact_name}
                    emergency_contact_number={
                      patients?.emergency_contact_number
                    }
                    emergency_contact_relation={
                      patients?.emergency_contact_relation
                    }
                    blood_group={patients?.blood_group}
                    insurance_company_name={patients?.insurance_company_name}
                    insured={patients?.insured}
                    membership_number={patients?.membership_number}
                    fetchDetails={fetchDetails}
                    fetchSubscriptionDetails={fetchSubscriptionDetails}
                  />
                </div>

                <div className="site-layout-content">
                  <span>
                    {" "}
                    <Title level={3}>Basic Info </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={6}>
                        <Card size="small" title="Name" bordered={false}>
                          <span>
                            {`${patients?.patient_first_name}` +
                              " " +
                              `${patients?.patient_last_name}`}
                          </span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Email" bordered={false}>
                          <span>{patients?.patient_email}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Gender" bordered={false}>
                          <span>{patients?.patient_gender}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="DOB" bordered={false}>
                          <span>{patients?.patient_dob}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card
                          size="small"
                          title="Health Issues"
                          bordered={false}
                        >
                          <span>{patients?.primary_health_issue}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Allergies" bordered={false}>
                          <span>{patients?.allergies}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Phone" bordered={false}>
                          <span>{patients?.patient_mobile}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Blood Group" bordered={false}>
                          <span>{patients?.blood_group}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <span>
                    {" "}
                    <Title level={3}> Contact Info </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={6}>
                        <Card
                          size="small"
                          title="Address Line 1"
                          bordered={false}
                        >
                          <span>{patients?.primary_address_line_one}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card
                          size="small"
                          title="Address Line 2"
                          bordered={false}
                        >
                          <span>{patients?.primary_address_line_two}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="City" bordered={false}>
                          <span>{patients?.patient_city}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="State" bordered={false}>
                          <span>{patients?.patient_state}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Country" bordered={false}>
                          <span>{patients?.patient_country}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Pincode" bordered={false}>
                          <span>{patients?.patient_pincode}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <span>
                    {" "}
                    <Title level={3}> Emergency Info </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Emergency Contact Name"
                          bordered={false}
                        >
                          <span>{patients?.emergency_contact_name}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Emergency Contact Number"
                          bordered={false}
                        >
                          <span>{patients?.emergency_contact_number}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Emergency Contact Relation"
                          bordered={false}
                        >
                          <span>{patients?.emergency_contact_relation}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card size="small" title="Allergies" bordered={false}>
                          <span>{patients?.allergies}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <span>
                    <Title level={3}> Insurance Info</Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Insurance Company Name"
                          bordered={false}
                        >
                          <span>{patients?.insurance_company_name}</span>
                        </Card>
                      </Col>

                      <Col span={8}>
                        <Card
                          size="small"
                          title="Insurace Number"
                          bordered={false}
                        >
                          <span>{patients?.insurance_number}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Insurance Validity"
                          bordered={false}
                        >
                          <span>{patients?.insurance_validity}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <span>
                    {" "}
                    <Title level={3}> Subscription Info</Title>
                  </span>
                  <Divider />
                  <Row gutter={16}>
                    <Col span={8}>
                      <Card
                        size="small"
                        title="Membership Number"
                        bordered={false}
                      >
                        <span>{patients?.membership_number}</span>
                      </Card>
                    </Col>
                  </Row>
                  {subscription?.map((data) => (
                    <Row gutter={32}>
                      <Col span={8}>
                        <Title level={4}>{data.name}</Title>
                        <Title level={5}>Status : {data?.status}</Title>
                        <Text strong>
                          {data?.startDate} -{"\u00A0"} {data?.endDate}
                        </Text>
                        <List
                          bordered
                          dataSource={data.description}
                          renderItem={(item) => (
                            <List.Item key={item}>{item}</List.Item>
                          )}
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        />
                      </Col>
                    </Row>
                  ))}
                </div>
              </>
            ) : (
              <Spin
                size="large"
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            )}
          </Content>
          <Footer style={{ textAlign: "center" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default PatientDetails;
