import React, { useState, useEffect } from "react";
import { Layout, Menu, Form, Input, Button, Select } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import {
  Descriptions,
  message,
  Spin,
  Divider,
  Table,
  Space,
  Modal,
  Popconfirm,
} from "antd";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import "./externalAppointments.styles.css";
import InputForm from "../../Components/medicalreports/form.component";
import MedicationForm from "../../Components/medications/medication-form.component";
import moment from "moment";
import ExternalAppointments from "./externalAppointments.component";

const { Option } = Select;

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailLayout = {
  wrapperCol: { offset: 16, span: 24 },
};

const CallFeedback = (props) => {
  const [medications, setMedications] = useState([]);
  const [medicalreports, setMedicalReports] = useState([]);
  const [patient, setPatient] = useState([]);
  const [loading, setToggle] = useState(false);
  const [spin, setSpin] = useState(false);
  const [record, setRecord] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState("reports");
  const [visible, setVisible] = useState(false);

  const AppointmentId = props?.match?.params?.id
    ? props.match.params.id
    : props.appt_id;

  const DisplayNotes = props?.showNotes;
  const PatientId = props?.patient_id;
  const ProviderNotes = props?.value;
  const Remarks = props?.remarks;
  const [form] = Form.useForm();

  const reportsColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },

    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: "Document Date",
      dataIndex: "generation_date",
      key: "generation_date",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              setRecord(record);
              // setToggle(false);
              setCategory("reports");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const medicationColumns = [
    {
      title: "Drug Name",
      render: (record) => `${record.drug_name} - ${record.drug_strength}`,
      key: "drug_name",
    },
    {
      title: "Drug Form",
      dataIndex: "drug_form",
      key: "drug_form",
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setRecord(record);
              // setRecordsToggle(() => false);
              setCategory("medication");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    setSpin(true);
    let data = {};
    if (ProviderNotes && values.status && values.Remarks) {
      data = {
        provider_notes: ProviderNotes,
        display_provider_notes: DisplayNotes,
        status: values.status,
        remarks: values.Remarks,
      };
    } else if (ProviderNotes && values.status) {
      data = {
        provider_notes: ProviderNotes,
        display_provider_notes: DisplayNotes,
        status: values.status,
      };
    } else {
      data = {
        remarks: values.Remarks,
        status: values.status,
      };
    }

    axios
      .put(`https://app02.thbglobal.com/v1/appointments/${AppointmentId}`, data)
      .then((result) => {
        if (result.data.type === "Success") {
          form.resetFields();
          setSpin(false);
          message.success(
            "Your remarks and/or medications & file data has been stored successfully"
          );
          setTimeout(() => {
            setVisible(true);
          }, 3000);
          props.setShowTable(true);
          props.setLoader(false);
          props.setRecord([]);
        }
      })
      .catch((e) => {
        message.error(e);
      });
  };

  const fetchData = () => {
    const url = `https://app02.thbglobal.com/v1/patients?usertype=healthManager&userid=0`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          result.data.forEach((data, index) => {
            if (data.patient_id === parseInt(PatientId)) {
              setPatient(result.data[index]);
            }
          });
          setToggle(true);
        } else {
          setToggle(true);
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => setToggle(false);
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  const handleMedications = (data) => {
    setMedications([data]);
  };

  const handleMedicalReports = (data) => {
    setMedicalReports([data]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function confirm() {
    setVisible(false);
  }

  const cancel = () => {
    window.location.href = "/externalvisits";
    setVisible(false);
  };

  function downloadPDF(filecontent, filename) {
    var w = window.open("about:blank");

    var signatures = {
      JVBERi0: "application/pdf",
      "/9j/": "image/jpeg",
      iVBORw0KGgo: "image/png",
    };

    const read = new FileReader();

    read.readAsBinaryString(filecontent);

    // function detectMimeType(b64) {
    //   for (var s in signatures) {
    //     if (b64.indexOf(s) === 0) {
    //       return signatures[s];
    //     }
    //   }
    // }

    // let mimeType = detectMimeType(filecontent);

    setTimeout(function () {
      //FireFox seems to require a setTimeout for this to work.
      // w.document.body.appendChild(
      // w.document.write(
      //   "<iframe width='100%' height='100%' src='data:" +
      //     mimeType +
      //     ";base64, " +
      //     encodeURI(filecontent) +
      //     "'></iframe>"
      // );
    }, 0);
  }

  return (
    <>
      {props?.external ? (
        <>
          <h4>Notes To Patient</h4>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            initialValues={{
              status: "attended",
              Remarks: Remarks,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="Remarks"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select allowClear style={{ width: 120 }}>
                <Option key="cancelled" value="cancelled">
                  Cancelled
                </Option>
                <Option key="attended" value="attended">
                  Attended
                </Option>
                <Option key="missed" value="missed">
                  Missed
                </Option>
                <Option key="not_attended" value="not_attended">
                  Not Attended
                </Option>
              </Select>
            </Form.Item>

            <Divider />

            <MedicationForm
              onMedicationSubmit={handleMedications}
              appt_id={AppointmentId}
              patient_id={PatientId}
              presciption={"Add Prescription"}
            />

            <h4 style={{ marginLeft: "10px", marginTop: "10px" }}>
              Presciption
            </h4>
            {medications && Object.keys(medications).length !== 0 && (
              <Table
                dataSource={medications}
                columns={medicationColumns}
                rowKey="medication_id"
                pagination={{ position: ["bottomCenter"] }}
              />
            )}

            <InputForm
              onMedicalSubmit={handleMedicalReports}
              appt_id={AppointmentId}
              patient_id={PatientId}
              document={"Upload Document"}
            />
            <h4 style={{ marginLeft: "10px", marginTop: "10px" }}>Documents</h4>
            {medicalreports && Object.keys(medicalreports).length !== 0 && (
              <Table
                dataSource={medicalreports}
                columns={reportsColumns}
                pagination={{ position: ["bottomCenter"] }}
              />
            )}

            <span style={{ display: "block", height: "10px" }}></span>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {spin ? <Spin size="small" /> : `Submit`}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
          <Modal
            title="Details"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
          >
            <DataPreview data={record} type={category} />
          </Modal>
        </>
      ) : (
        <div className="appointments">
          <Layout className="layout">
            <Header>
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link to="/dashboard">Back to Dashboard</Link>
                </Menu.Item>
              </Menu>
            </Header>
            <Layout hasSider="true">
              <Sider
                collapsible
                width="250px"
                className="site-layout-background"
              >
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={["9"]}
                  style={{ height: "100%", borderRight: 0 }}
                >
                  <Menu.Item key="1" icon={<UserOutlined />}>
                    <Link to="/dashboard"> Patients </Link>
                  </Menu.Item>

                  <Menu.Item key="2" icon={<UserOutlined />}>
                    <Link to="/externalappointments">My Appointments</Link>
                  </Menu.Item>
                  <SubMenu
                    key="sub1"
                    icon={<UserOutlined />}
                    title="User Management"
                  >
                    <Menu.Item key="3">
                      <Link to="/internaluser">Internal User Management</Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                      {" "}
                      <Link to="/externaluser">External User Management</Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="5" icon={<UserOutlined />}>
                    Services Management
                  </Menu.Item>
                  <Menu.Item key="6" icon={<UserOutlined />}>
                    <Link to="/healthboard"> Health Board Management </Link>
                  </Menu.Item>
                  <Menu.Item key="7" icon={<UserOutlined />}>
                    <Link to="/subscription"> Subscriptions Management </Link>
                  </Menu.Item>
                  <Menu.Item key="8" icon={<UserOutlined />}>
                    <Link to="/reportsdashboard"> Reports & Dashboard</Link>
                  </Menu.Item>
                  <Menu.Item key="11" icon={<UserOutlined />}>
                    <Link to="/consultation">My Consultation Slots </Link>
                  </Menu.Item>
                </Menu>
              </Sider>

              <Content style={{ padding: "0 50px" }}>
                <div className="appointments-container">
                  <div className="page-title">
                    <h1>Post Consultation Procedure</h1>
                  </div>
                </div>

                {!props.external && (
                  <ExternalAppointments
                    AppointmentId={AppointmentId}
                    callFeedback={true}
                  />
                )}
              </Content>
              {/* <Footer style={{ textAlign: "right" }}></Footer> */}
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};
export default CallFeedback;

export const DataPreview = ({ data, type }) => {
  return (
    <>
      {type === "medication" ? (
        <Descriptions
          bordered
          title="Medications"
          size="large"
          column={1}
          labelStyle={{ backgroundColor: "#dad2d2" }}
        >
          <Descriptions.Item label="Dosage">{data.dosage}</Descriptions.Item>
          <Descriptions.Item label="Drug Form">
            {data.drug_form}
          </Descriptions.Item>
          <Descriptions.Item label="Drug Name">
            {data.drug_name}
          </Descriptions.Item>
          <Descriptions.Item label="Drug Strength">
            {data.drug_strength}
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            {data.end_date}
          </Descriptions.Item>
          <Descriptions.Item label="Frequency">
            {data.frequency}
          </Descriptions.Item>
          <Descriptions.Item label="Instructions">
            {data.instructions}
          </Descriptions.Item>
          <Descriptions.Item label="Intake Method">
            {data.intake_method}
          </Descriptions.Item>
          <Descriptions.Item label="Prescribed On">
            {data.prescribed_on}
          </Descriptions.Item>
          <Descriptions.Item label="Prescribed By">
            {data.prescribed_by}
          </Descriptions.Item>
          <Descriptions.Item label="Start Date">
            {data.start_date}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Descriptions
          bordered
          title="Medical Report"
          size="large"
          // layout="vertical"
          column={1}
          labelStyle={{ backgroundColor: "#dad2d2" }}
        >
          <Descriptions.Item label="Record Name">
            {data.file_name}
          </Descriptions.Item>
          <Descriptions.Item label="File Name">
            {data.record_name}
          </Descriptions.Item>
          <Descriptions.Item label="Category">
            {data.category}
          </Descriptions.Item>
          <Descriptions.Item label="Document Date">
            {data.generation_date}
          </Descriptions.Item>
          <Descriptions.Item label="Facility Name">
            {data.facility_name}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Uploaded On">
    {data.created_at}
  </Descriptions.Item>
  <Descriptions.Item label="Uploaded By">
    {data.upoaded_by}
  </Descriptions.Item> */}
          {/* <Descriptions.Item label="File Content">
    {
      <Button
        type="primary"
        size="small"
        onClick={() =>
          downloadPDF(
            data.file_content,
            data.file_name
          )
        }
        style={{ marginLeft: "5px" }}
      >
        View File
      </Button>
    }
  </Descriptions.Item> */}
          <Descriptions.Item label="Notes">{data.notes}</Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
