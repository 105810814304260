import React from "react";
import { Tabs, DatePicker, Menu, Layout, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import BloodPressure from "./bloodpressure.js";
import Respirationrate from "./respiration.js";
import Temperature from "./temp.js";
import Hydration from "./hydration.js";
import Height from "./height.js";
import Weight from "./weight.js";
import Painscaler from "./pain.js";
import Coma from "./coma.js";
import Bca from "./bca.js";
import Steps from "./steps.js";
import Calories from "./calories.js";
import Pulse from "./pulse.js";
import Lmp from "./lmp.js";
import "../../vitals/Vital-Scss/vitals.scss";
import Temp from "../../temp/temp.component";
import Sleep from "./sleep.js";
import BloodSugar from "./bloodsugar.js";
import Fat from "./fat.js";
import OxygenSaturation from "./oxygensaturation.js";
import VitalsForm from "../vitalsForm.component.jsx";
import "../vitals.css";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const data = [
  { key: "bloodpressure", value: ["mmHg"] },
  { key: "fat", value: ["%"] },
  { key: "oxygen_saturation", value: ["None"] },
  { key: "sleep", value: ["hrs per day"] },
  { key: "pulse", value: ["per minute"] },
  { key: "steps", value: ["per day"] },
  { key: "temperature", value: ["C", "F"] },
  { key: "weight", value: ["kg", "lbs", "oz"] },
  { key: "bloodsugar", value: ["mg/dl"] },
  { key: "bca", value: ["None"] },
];

let dict = {};
for (var i = 1; i < data.length + 1; i++) {
  dict[i] = data[i - 1];
}

let retrievedState = JSON.parse(localStorage.getItem("myState"));

export default class VitalsComponent extends React.Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.state = {
      filteredData: [],
      vitalSelected: "bloodpressure",
      unit: ["mmHg"],
      d: "",
      s: "",
      toggle: false,
    };
  }

  componentDidUpdate() {
    console.log("object");
  }

  // PatientId = this.props.match.params.id;
  onChange = (key) => {
    this.setState(
      {
        vitalSelected: dict[key].key,
        unit: dict[key].value,
      },
      () => {
        console.log(this.state.vitalSelected, this.state.unit);
      }
    );
  };

  // handleChange = () => {
  //   this.setState({
  //     toggle:!this.state.toggle
  //   })
  //   this.forceUpdate();
  // }

  // handleChange = (date, dateString) => {
  //   this.setState({
  //     filteredData: dateString[1],
  //     d: dateString[0],
  //     s: dateString[1],
  //   });

  //   const { d, s } = this.state;
  //   fetch(
  //     `https://app02.thbglobal.com/v1/patients/${this.props.id}/vitals/temperature?startDate=${d}&endDate=${s}`
  //   )
  //     .then((res) => res.json())
  //     .then((data) => this.setState({

  //     }));
  // };

  render() {
    let active = sessionStorage.getItem("activeTab");
    const PatientId = this.props.match.params.id;
    const PatientName = this.props?.location?.param1?.name?.replace(
      "undefined",
      ""
    );
    const PatientEmail = this.props?.location?.param1?.email;
    const PatientMRN = this.props.location?.param1?.mrn;
    const PatientPhone = this.props.location?.param1?.phone;
    var params = {
      name: PatientName,
      email: PatientEmail,
      mrn: PatientMRN,
      phone: PatientPhone,
    };

    return (
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["4", "s3"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1", "4"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 20px" }}>
            <div className="medications-container">
              <div className="page-title">
                <h1>Vitals Data Representation</h1>
              </div>
              <VitalsForm
                vitalSelected={this.state.vitalSelected}
                unit={this.state.unit}
                p_id={this.props.match.params.id}
              />
            </div>
            {/* <div className="vital-layout-content"> */}
            <div className="vital-main-section">
              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              <Divider />
              <Tabs
                defaultActiveKey={active ? active : 1}
                tabPosition="top"
                style={{ height: 1000 }}
                onChange={this.onChange}
              >
                <TabPane tab={<span>Blood Pressure</span>} key="1">
                  <BloodPressure
                    s={this.state.s}
                    d={this.state.d}
                    p_id={this.props.match.params.id}
                  />
                </TabPane>
                <TabPane tab={<span>Body Fat</span>} key="2">
                  <Fat p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Oxygen Saturation</span>} key="3">
                  <OxygenSaturation p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Sleep</span>} key="4">
                  <Sleep p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Pulse</span>} key="5">
                  <Pulse p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Steps</span>} key="6">
                  <Steps p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Temperature</span>} key="7">
                  <Temperature p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Weight</span>} key="8">
                  <Weight p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>Blood Sugar</span>} key="9">
                  <BloodSugar p_id={this.props.match.params.id} />
                </TabPane>
                <TabPane tab={<span>BCA</span>} key="10">
                  <Bca p_id={this.props.match.params.id} />
                </TabPane>
              </Tabs>
            </div>
            {/* </div> */}
          </Content>
        </Layout>
      </Layout>
      // <div style={{display:"flex", flex:0.3,  justifyContent:"flex-end"}}>
    );
  }
}
