import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Card, Descriptions, Spin } from "antd";
import { Link } from "react-router-dom";
import { message } from "antd";
import { DatePicker } from "antd";
import axios from "axios";

import { UserOutlined } from "@ant-design/icons";
import "../appointments/appointments.styles.css";
import ConsultationForm from "../../Components/consultation/consultationForm.component";
import "antd/dist/antd.css";
import { Statistic, Row, Col, Modal, Form, Button } from "antd";
import {
  ClockCircleOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import SiderComponent from "../../Components/Sider.component";

const { Option } = Select;
const { Header, Content } = Layout;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const Consultation = (props) => {
  const [recordsLoader, setRecordsToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [pid, setId] = useState(0);
  const [slotId, setSlotId] = useState(0);
  const [status, setStatus] = useState("cancelled");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const Id = localStorage.getItem("userid");

  useEffect(() => {
    onFinish(moment());
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleStatus = (values) => {
    setLoading(true);
    const url = `https://app02.thbglobal.com/v1/doctors/${Id}/slots/${slotId}`;

    const data = {
      slot_status: values.Status,
    };

    axios
      .put(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        message.success("Data Updated");
        setLoading(false);
        setIsModalVisible(false);
        onFinish(moment());
      })
      .catch((err) => {
        message.error("Update Error", err);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (values) {
      setRecordsToggle(false);
      let slotDate = values.format("YYYY-MM-DD");

      const url = `https://app02.thbglobal.com/v1/doctors/${Id}/slots?slotDate=${slotDate}`;

      fetch(url, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.type === "Success") {
            setAppointmentsData(result.data);
            setRecordsToggle(true);
          } else {
            setRecordsToggle(true);
            message.warning("Can't load the data.");
          }
        })
        .catch((err) => {
          setRecordsToggle(true);
          message.error("Sorry, can't load the data at the moment.");
          console.log(err);
        });
    } else {
      setRecordsToggle(true);
      message.warning("Please Select the date");
    }
  };

  return (
    <div className="health-board">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="11" />
          <Content style={{ padding: "0 50px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>Consultations</h1>
              </div>
              <ConsultationForm id={pid} onFinish={onFinish} />
            </div>
            <div className="site-layout-content">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DatePicker
                  onChange={(values) => onFinish(values)}
                  defaultValue={moment()}
                />
              </div>

              {recordsLoader ? (
                <Descriptions
                  bordered
                  title="Consultation Details"
                  size="middle"
                  labelStyle={{ backgroundColor: "#D3D3D3" }}
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                  key="1"
                >
                  {appointmentsData.map((item, index) => {
                    let color;
                    item.slot_status === "cancelled"
                      ? (color = "#cf1322")
                      : item.slot_status === "scheduled"
                      ? (color = "#ececec")
                      : (color = "#1890ff");
                    return (
                      <>
                        <Row gutter={16}>
                          <Card
                            actions={[
                              <EditOutlined
                                title="Change Status"
                                onClick={() => {
                                  setStatus(item.slot_status);
                                  setSlotId(item.slot_id);
                                  setIsModalVisible(true);
                                }}
                                key="edit"
                              />,
                            ]}
                          >
                            <Col span={12}>
                              <Statistic
                                title="Start Time"
                                value={item.start_time}
                                prefix={<ClockCircleOutlined />}
                                key={index}
                                precision={2}
                                valueStyle={{ color: color }}
                              />

                              <Statistic
                                title="End Time"
                                value={item.end_time}
                                prefix={<ClockCircleOutlined />}
                                key={item.end_time}
                                precision={2}
                                valueStyle={{ color: color }}
                              />
                            </Col>
                          </Card>
                        </Row>
                      </>
                    );
                  })}
                </Descriptions>
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}

              <Modal
                title="Change Status"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                <Form
                  {...layout}
                  name="nest-messages"
                  onFinish={(values) => handleStatus(values)}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    name="Status"
                    label="Select Status"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    initialValue={status}
                  >
                    <Select allowClear onChange={(val) => setStatus(val)}>
                      <Option key="1" value="cancelled">
                        Cancelled
                      </Option>
                      <Option key="2" value="scheduled">
                        Scheduled
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                      {loading && <LoadingOutlined />}
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default Consultation;
