import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

class UpdateHealthBoard extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    this.setState({ loading: true });

    const url = `https://app02.thbglobal.com/v1/healthBoard/${this.props.hb_id}`;
    const data = {
      title: values.title,
      article_date: values["date-picker"].format("YYYY-MM-DD"),
      description: values.description,
      written_by: values.written_by,
      url: values.url,
      other_data_one: values.other_data_one,
    };

    axios
      .put(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        message.success("Data Updated");
        this.setState({ loading: false, visible: false });
        this.props.fetchHealthBoardData();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Update Error");
      });
  };

  handleChange = (e) => {
    let files = e.target.files;

    var filesize = (files[0]?.size / 1024 / 1024).toFixed(5); // MB
    if (filesize > 5) {
      alert("File Size too big");
    } else {
      this.setState({
        selectedFile: files[0],
      });
    }
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <div className="input-form">
          <EditOutlined
            title="Update"
            style={{ color: "blue", cursor: "pointer" }}
            onClick={this.showModal}
          />
        </div>
        <Modal
          visible={visible}
          title="Update Record"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
            initialValues={{
              title: this.props.title,
              written_by: this.props.written_by,
              description: this.props.description,
              url: this.props.url,
              other_data_one: this.props.other_data_one,
            }}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="date-picker"
              label="Article Date"
              initialValue={moment(this.props.article_date)}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select time!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="written_by"
              label="Written By"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="url"
              label="URL"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="other_data_one"
              label="Other Data"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item label="Picture">
              <input
                type="file"
                value={this.selectedFile}
                onChange={this.handleChange}
                name="file"
                accept="image/jpg, image/jpeg, image/png"
              />
              <span style={{ fontSize: "10px" }}>
                <strong>Accepted File Format :</strong> .jpg/.jpeg/.png |
              </span>
              <span style={{ fontSize: "10px" }}>
                {" "}
                <strong> File-Size :</strong> 5MB
              </span>
            </Form.Item> */}
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateHealthBoard;
