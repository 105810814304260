import React, { useState } from "react"
import './App.css';
import firebase from "firebase/app";
import { Route } from "react-router-dom";
import Dashboard from "./Pages/dashboard/dashboard.component";
import Homepage from "./Pages/homepage/homepage.component";
import Login from "./Pages/login/login.component";
import MedicalReports from './Pages/medical- reports/medical-reports.component';
import Appointments from './Pages/appointments/appointments.component';
import Medications from './Pages/medications/medications.component';
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";
import PatientDetails from "./Pages/patientsdetails/patientDetails.component";
import VitalsComponent from "./Components/vitals/Vital-Js";
import Temp from "./Components/temp/temp.component";
import HealthBoard from "./Pages/healthboard/healthboard";
import ClinicalVisits from "./Pages/clinicalvisits/clinicalVisits.component";
import InternalUserManagement from "./Pages/internaluser/internalUserManagement";
import PasswordReset from "./Pages/login/passwordReset.component";
import ExternalAppointments from "./Pages/external-appointments/externalAppointments.component";
import CallFeedback from "./Pages/external-appointments/callFeedback.component";
import ExternalUserManagement from "./Pages/externaluser/externalUserManagement";
import Zoom from "./Components/video/zoom";
import Subscriptions from "./Pages/subcription-management/subscription";
import Consultation from "./Pages/consultation/Consultation";
import VitalsDashboard from "./Pages/vitalsPreview/vitalsDashboard";
import ReportsDashboard from "./Pages/reports-dashboard/reportsDashboard";
import ExternalClinicalVisits from "./Pages/external-clinicalvisits/externalclinicalvisits";
import Earnings from "./Pages/earnings/earnings";
import ExternalAppointmentsDetails from "./Pages/external-appointments/externalAppointmentsDetails.component";
import { onMessageListener } from "./firebaseInit";
import ReactNotificationComponent from "./Components/notifications/ReactNotification";
import MyProfile from "./Pages/profile/MyProfile.component";

function App() {

  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });


  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));
  
  
  const setTokens = (data) => {
    console.log("token",)
    localStorage.setItem("tokens", JSON.stringify(data.split("'")[1]));
    setAuthTokens(data);
  }
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
    <div className="App">
    {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <Route exact path="/" component={Homepage}/>
      <PrivateRoute path="/dashboard" component={Dashboard}/>
      <Route path="/login" component={Login}/>
      <Route path="/reset" component={PasswordReset}/>
      <PrivateRoute path="/video/:id" component={Zoom}/>
      <PrivateRoute path="/medicalreports/:id" component={MedicalReports}/>
      <PrivateRoute path="/appointments/:id" component={Appointments}/>
      <PrivateRoute path="/clinicalvisits/:id" component={ClinicalVisits}/>
      <PrivateRoute path="/externalvisits" component={ExternalClinicalVisits}/>
      <PrivateRoute path="/earnings" component={Earnings}/>
      <PrivateRoute path="/medications/:id" component={Medications}/>
      <PrivateRoute path="/vitalsdata/:id" component={VitalsComponent}/>
      <PrivateRoute path="/vitals/:id" component={VitalsDashboard}/>
      <PrivateRoute path='/patientsdetails/:id' component={PatientDetails}/>
      <PrivateRoute path='/profile' component={MyProfile}/>
      <PrivateRoute path='/healthboard' component={HealthBoard}/>
      <PrivateRoute path='/reportsdashboard' component={ReportsDashboard}/>
      <PrivateRoute path='/subscription' component={Subscriptions}/>
      <PrivateRoute path='/internaluser' component={InternalUserManagement}/>
      <PrivateRoute path='/externaluser' component={ExternalUserManagement}/>
      <PrivateRoute path='/consultation' component={Consultation}/>
      <PrivateRoute path='/externalappointments' component={ExternalAppointments}/>
      <PrivateRoute path='/externalappointmentsdetails/:id' component={ExternalAppointmentsDetails}/>
      <PrivateRoute path='/callfeedback/:id' component={CallFeedback}/>
      <PrivateRoute path='/temp' component={Temp}/>
    </div>
    </AuthContext.Provider>
  );

};


export default App;
