import React, { useState, useEffect, useRef } from "react";
import { Layout, Menu, List } from "antd";
import { Link } from "react-router-dom";
import { Divider, Spin } from "antd";
import { Card, Col, Row } from "antd";
import { Typography } from "antd";

import { UserOutlined } from "@ant-design/icons";

import "../appointments/appointments.styles.css";
import UpdateProfile from "../../Components/MyProfile/updateProfile.component";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const MyProfile = (props) => {
  const [loader, setToggle] = useState(false);
  const [profile, setProfile] = useState([]);

  let DoctorId = localStorage.getItem("userid");

  const fetchProfileDetails = () => {
    const url = `https://app02.thbglobal.com/v1//doctors/${DoctorId}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setProfile(result.data);
        setToggle(true);
      });
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  return (
    <div className="patients-details">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal" style={{ float: "left" }}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <SiderComponent selectedKey="13" />
          <Content style={{ padding: "0 50px" }}>
            {loader ? (
              <>
                <div className="appointments-container">
                  <div className="page-title">
                    <h1>My Profile</h1>
                  </div>
                  <UpdateProfile
                    id={DoctorId}
                    name={profile?.name}
                    phone={profile?.phone}
                    email={profile?.email}
                    gender={profile?.gender}
                    dob={profile?.dob}
                    address_line_1={profile?.address1}
                    address_line_2={profile?.address2}
                    city={profile?.city}
                    pincode={profile?.pincode}
                    profession={profile?.profession}
                    state={profile?.state}
                    country={profile?.country}
                    qualification_one={profile?.qualification_one}
                    qualification_two={profile?.qualification_two}
                    specialization={profile?.specialization}
                    license_number={profile?.license_number}
                    fetchDetails={fetchProfileDetails}
                  />
                </div>

                <div className="site-layout-content">
                  <span>
                    {" "}
                    <Title level={3}>Basic Info </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={6}>
                        <Card size="small" title="Name" bordered={false}>
                          <span>{profile?.name}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Email" bordered={false}>
                          <span>{profile?.email}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Gender" bordered={false}>
                          <span>{profile?.gender}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="DOB" bordered={false}>
                          <span>{profile?.dob}</span>
                        </Card>
                      </Col>

                      <Col span={6}>
                        <Card size="small" title="Phone" bordered={false}>
                          <span>{profile?.phone}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Profession" bordered={false}>
                          <span>{profile?.profession}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <span>
                    {" "}
                    <Title level={3}> Contact Info </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={6}>
                        <Card
                          size="small"
                          title="Address Line 1"
                          bordered={false}
                        >
                          <span>{profile?.address1}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card
                          size="small"
                          title="Address Line 2"
                          bordered={false}
                        >
                          <span>{profile?.address2}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="City" bordered={false}>
                          <span>{profile?.city}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="State" bordered={false}>
                          <span>{profile?.state}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Country" bordered={false}>
                          <span>{profile?.country}</span>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card size="small" title="Pincode" bordered={false}>
                          <span>{profile?.pincode}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <span>
                    {" "}
                    <Title level={3}> Qualification </Title>
                  </span>
                  <Divider />
                  <div className="site-card-wrapper">
                    <Row gutter={32}>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Primary Qualification"
                          bordered={false}
                        >
                          <span>{profile?.qualification_one}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Secondary Qualification"
                          bordered={false}
                        >
                          <span>{profile?.qualification_two}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="Specialization"
                          bordered={false}
                        >
                          <span>{profile?.specialization}</span>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          size="small"
                          title="License Number"
                          bordered={false}
                        >
                          <span>{profile?.license_number}</span>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            ) : (
              <Spin
                size="large"
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            )}
          </Content>
          <Footer style={{ textAlign: "center" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default MyProfile;
