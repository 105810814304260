import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, message } from "antd";
import { Statistic, Card, Row, Col } from "antd";
import { DatePicker } from "antd";
import { subDays, format } from "date-fns";

import { UserOutlined } from "@ant-design/icons";
import "../appointments/appointments.styles.css";
import SiderComponent from "../../Components/Sider.component";

const { Option } = Select;
const { Header, Content } = Layout;
const { RangePicker } = DatePicker;

const ReportsDashboard = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(
    format(subDays(new Date(), 30), "yyyy-MM-dd")
  );
  const [category, setCategory] = useState("users");
  const [dateflag, setDateFlag] = useState(0);

  const PatientId = props.match.params.id;

  const patientsColumns = [
    {
      title: "Name",
      dataIndex: "patient_first_name",
      key: "patient_first_name",
    },
    {
      title: "Email",
      dataIndex: "patient_email",
      key: "patient_email",
    },
    {
      title: "Phone",
      dataIndex: "patient_mobile",
      key: "patient_mobile",
    },
  ];
  const doctorsColumns = [
    {
      title: "Name",
      dataIndex: "doctor_name",
      key: "doctor_name",
    },
    {
      title: "Email",
      dataIndex: "doctor_email",
      key: "doctor_email",
    },
    {
      title: "Phone",
      dataIndex: "doctor_phone",
      key: "doctor_phone",
    },
  ];
  const healthManagersColumns = [
    {
      title: "Name",
      dataIndex: "hm_name",
      key: "hm_name",
    },
    {
      title: "Email",
      dataIndex: "hm_email",
      key: "hm_email",
    },
  ];

  const appointmentsColumns = [
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  const paymentsColumns = [
    {
      title: "Payment Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
  ];

  useEffect(() => {
    fetchDashboardData();
  }, [dateflag]);

  useEffect(() => {
    getReportsByCategory();
  }, [dateflag, category]);

  const fetchDashboardData = () => {
    const url = `https://app02.thbglobal.com/v1/dashboard?startDate=${startDate}&endDate=${endDate}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          console.log(result);
          setDashboardData(result.data);
          setReportsToggle(true);
        } else {
          message.warning("Can't get the dashboard data.");
          setReportsToggle(true);
        }
      });
  };

  const getReportsByCategory = () => {
    const url = `https://app02.thbglobal.com/v1/reports/${category}?startDate=${startDate}&endDate=${endDate}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setReports(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
          message.warning("Can't load the data.");
        }
      });
  };

  const handleChange = (date, dateString) => {
    if (dateString[0] && dateString[1]) {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
      setDateFlag(dateflag + 1);
    } else {
      setStartDate(format(new Date(), "yyyy-MM-dd"));
      setEndDate(format(subDays(new Date(), 30), "yyyy-MM-dd"));
    }
  };

  const handleCategory = (value) => {
    setRecordsToggle(false);
    setCategory(value);
  };

  return (
    <div className="health-board">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="8" />
          <Content style={{ padding: "0 50px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>Reports and Dashboard</h1>
              </div>
              <RangePicker
                size={`small`}
                onChange={(date, dateString) => handleChange(date, dateString)}
                style={{
                  background: "none",
                  marginLeft: "auto",
                  marginTop: "5px",
                  border: "none",
                }}
              />
            </div>
            <div className="site-layout-content">
              <div className="site-statistic-demo-card">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title="Patients"
                        value={dashboardData.patients}
                        valueStyle={{ color: "#3f8600" }}
                        // prefix={<ArrowUpOutlined />}
                        // suffix="%"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title="Doctors"
                        value={dashboardData.doctors}
                        valueStyle={{ color: "#3f8600" }}
                        // prefix={<ArrowDownOutlined />}
                        // suffix="%"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card style={{ marginBottom: "15px" }}>
                      <Statistic
                        title="Health Manager"
                        value={dashboardData.healthManagers}
                        valueStyle={{ color: "#3f8600" }}
                        // prefix={<ArrowDownOutlined />}
                        // suffix="%"
                      />
                    </Card>
                  </Col>

                  <Col span={8}>
                    <Card>
                      <Statistic
                        title="Appointments"
                        value={dashboardData.appointments}
                        valueStyle={{ color: "#3f8600" }}
                        // prefix={<ArrowDownOutlined />}
                        // suffix="%"
                      />
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <Statistic
                        title="Payments"
                        value={dashboardData.payments}
                        valueStyle={{ color: "#3f8600" }}
                        // prefix={<ArrowDownOutlined />}
                        // suffix="%"
                      />
                    </Card>
                  </Col>
                </Row>
              </div>

              <Divider />
              <div>
                <Select
                  defaultValue="users"
                  style={{ width: "120px" }}
                  onChange={handleCategory}
                >
                  <Option value="healthManagers">Health Managers</Option>
                  <Option value="doctors">Doctors</Option>
                  <Option value="users">Patients</Option>
                  <Option value="appointments">Appointments</Option>
                  <Option value="payments">Payments</Option>
                </Select>
              </div>

              {recordsLoader ? (
                <Table
                  dataSource={reports}
                  columns={
                    category === "users"
                      ? patientsColumns
                      : category === "doctors"
                      ? doctorsColumns
                      : category === "healthManagers"
                      ? healthManagersColumns
                      : category === "appointments"
                      ? appointmentsColumns
                      : paymentsColumns
                  }
                  rowKey={1}
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
              {/* <h1 style={{ marginLeft: "10px" }}>Select Patient</h1>*/}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "right" }}>
        </Footer>
    */}
        </Layout>
      </Layout>
    </div>
  );
};
export default ReportsDashboard;
