import React from "react";
import { Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const SiderComponent = ({ selectedKey, defaultOpenKeys }) => {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const role = localStorage.getItem("usertype");
  return (
    <div>
      <Sider collapsible width="250px" className="site-layout-background">
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[selectedKey]}
          defaultOpenKeys={[defaultOpenKeys]}
          style={{ height: "100%", borderRight: 0 }}
        >
          {role === "admin" ? (
            <>
              <SubMenu
                key="sub1"
                icon={<UserOutlined />}
                title="User Management"
              >
                <Menu.Item key="4">
                  <Link to="/internaluser">Internal User Management</Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/externaluser">External User Management</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to="/healthboard"> Health Board Management </Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<UserOutlined />}>
                <Link to="/subscription"> Subscriptions Management </Link>
              </Menu.Item>
              <Menu.Item key="8" icon={<UserOutlined />}>
                <Link to="/reportsdashboard"> Reports & Dashboard</Link>
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/dashboard"> Patients </Link>
              </Menu.Item>

              <Menu.Item key="2" icon={<UserOutlined />}>
                <Link to="/externalappointments">My Appointments</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<UserOutlined />}>
                <Link to="/externalvisits">My Clinical Visits</Link>
              </Menu.Item>
              {/* <Menu.Item key="8" icon={<UserOutlined />}>
                Roles & Permission
              </Menu.Item> */}

              {/* <Menu.Item key="10" icon={<UserOutlined />}>
                My Account
              </Menu.Item> */}
              <Menu.Item key="11" icon={<UserOutlined />}>
                <Link to="/consultation">My Consultation Slots </Link>
              </Menu.Item>
              <Menu.Item key="12" icon={<UserOutlined />}>
                <Link to="/earnings">My Earnings </Link>
              </Menu.Item>
              <Menu.Item key="13" icon={<UserOutlined />}>
                <Link to="/profile">My Profile </Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
    </div>
  );
};

export default SiderComponent;
