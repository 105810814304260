import React, { useEffect } from "react";
import axios from "axios";
import MetaTags from "react-meta-tags";

const development = "https://web.thbglobal.com";
const local = "http://localhost:3000";
const staging = "https://thbstaging.netlify.app";

const Zoom = (props) => {
  const host = window.location.host;

  var patientId = localStorage.getItem("userid");
  var AppointmentId = props.match.params.id;
  var Purpose = localStorage.getItem("purpose");
  var apiKey = "GSApot-ATlm2Hm9gYaRcBQ";
  var leaveUrl = `${
    host === "localhost:3000"
      ? local
      : host.includes("web")
      ? development
      : staging
  }/callfeedback/${AppointmentId}`; // our redirect url
  var userName = localStorage.getItem("username");
  var userEmail = localStorage.getItem("useremail");

  const fetchMeetingDetails = () => {
    const url = `https://app02.thbglobal.com/v1/zoom/generateMeeting`;

    const data = {
      requester_type: "doctors",
      requester_id: parseInt(patientId),
      appointment_id: parseInt(AppointmentId),
      appointment_purpose: Purpose,
    };
    const headers = {
      "x-api-key": `Bearer ${localStorage.getItem("tokens")}`,
    };

    return axios.post(url, data, { headers: headers });
  };

  const fetchMeetingToken = (meetingNumber, role) => {
    const url = `https://app02.thbglobal.com/v1/vidyo/generateToken/${userEmail}?meetingNumber=${meetingNumber}&role=${role}`;

    const headers = {
      "x-api-key": `Bearer ${localStorage.getItem("tokens")}`,
    };

    return axios({ method: "get", url: url, headers: headers });
  };

  // loading zoom libraries before joining on component did mount
  useEffect(() => {
    const { ZoomMtg } = require("@zoomus/websdk");
    showZoomDIv();
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.9/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    fetchMeetingDetails().then((res) => {
      console.log(res.data);
      var meetingNumber = res.data.data.meeting_id;
      console.log(meetingNumber);
      const role = 1;
      var passWord = res.data.data.meeting_password;
      fetchMeetingToken(meetingNumber, role).then((res) => {
        console.log(res.data);
        const signature = res.data.data.web_signature;
        console.log(signature);
        initiateMeeting(ZoomMtg, signature, passWord, meetingNumber);
      });
    });
    // initiateMeeting(ZoomMtg);
  }, []);

  const showZoomDIv = () => {
    document.getElementById("zmmtg-root").style.display = "block";
  };

  const initiateMeeting = (ZoomMtg, signature, passWord, meetingNumber) => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <>
      <MetaTags>
        {/* SIMD origin trials */}
        <meta
          http-equiv="origin-trial"
          content="Aj9cHB0lyZhr9xJrx/bnkFQxWNtiCEeEf4gcvNqPjGtz1D++JyH6euwZGQAWulFdQWdGmSDDylfKvx8oKdk7dA0AAABteyJvcmlnaW4iOiJodHRwczovL3dlYi50aGJnbG9iYWwuY29tOjQ0MyIsImZlYXR1cmUiOiJXZWJBc3NlbWJseVNpbWQiLCJleHBpcnkiOjE2NDk4MDc5OTksImlzU3ViZG9tYWluIjp0cnVlfQ=="
        />
        {/* Web Codecs  */}
        <meta
          http-equiv="origin-trial"
          content="Aqi5kXAOvHQuuVpaEW+yYkWVc2doZqsPZakYn1EXexqXXUv0yyHVrX6SZWElkKkUjSi991DcNfMC/V7/6rz+Aw4AAABMeyJvcmlnaW4iOiJodHRwOi8vbG9jYWxob3N0OjMwMDAiLCJmZWF0dXJlIjoiV2ViQ29kZWNzIiwiZXhwaXJ5IjoxNjM4NDAzMTk5fQ=="
        />
        {/* Shared Array Buffer  */}
        <meta
          http-equiv="origin-trial"
          content="AhTecnUErp+TCoaQZPxNXRHbz3f31pnbT1IHehQd7vY4NO9YOgZ8PsEeD1ijJT7sCjldkorin3HbTnLaBecPVw4AAAB7eyJvcmlnaW4iOiJodHRwczovL3dlYi50aGJnbG9iYWwuY29tOjQ0MyIsImZlYXR1cmUiOiJVbnJlc3RyaWN0ZWRTaGFyZWRBcnJheUJ1ZmZlciIsImV4cGlyeSI6MTY1ODg3OTk5OSwiaXNTdWJkb21haW4iOnRydWV9"
        />
      </MetaTags>
      <div id="zmmtg-root"></div>
    </>
  );
};

export default Zoom;
