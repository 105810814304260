import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Spin, Descriptions } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space } from "antd";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import MedicationForm from "../../Components/medications/medication-form.component";
import "./medications.styles.css";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Medications = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [medications, setMedications] = useState([]);
  const [medicationRecord, setMedicationRecord] = useState([]);
  const [pid, setId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const PatientId = props.match.params.id;
  const PatientName = props.location?.param1?.name?.replace("undefined", "");
  const PatientEmail = props.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var params = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };

  const columns = [
    {
      title: "Drug Name",
      render: (record) => `${record.drug_name} - ${record.drug_strength}`,
      key: "drug_name",
    },
    {
      title: "Drug Form",
      dataIndex: "drug_form",
      key: "drug_form",
    },
    {
      title: "Platform",
      key: "prescribed_on",
      render: (record) =>
        record.prescribed_on === "On Platform"
          ? "ePrescription"
          : record.prescribed_on,
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              getSingleMedication(record.patient_id, record.medication_id);
              setRecordsToggle(() => false);
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchMedications();
    setId(PatientId);
  }, []);

  const fetchMedications = () => {
    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}/prescriptions`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setMedications(result.data);

          setReportsToggle(() => true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  const getSingleMedication = (p_id, m_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${p_id}/prescriptions/${m_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setMedicationRecord(result.data);
          setRecordsToggle(() => true);
        } else {
          setRecordsToggle(true);
        }
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="medical-reports">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["5"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 50px" }}>
            <div className="medications-container">
              <div className="page-title">
                <h1>Medications</h1>
              </div>
              <MedicationForm
                id={pid}
                fromMedications={true}
                fetchMedications={fetchMedications}
              />
            </div>
            <div className="site-layout-content">
              {/* <span> Medication Details</span> */}
              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              <Divider />
              {reportsLoader ? (
                <Table
                  dataSource={medications}
                  columns={columns}
                  rowKey="medication_id"
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
              {/* <h1 style={{ marginLeft: "10px" }}>Select Patient</h1>*/}

              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {recordsLoader ? (
                  <Descriptions
                    bordered
                    title="Medication"
                    size="large"
                    column={1}
                    labelStyle={{ backgroundColor: "#dad2d2" }}
                  >
                    <Descriptions.Item label="Dosage">
                      {medicationRecord.dosage}
                    </Descriptions.Item>
                    <Descriptions.Item label="Drug Form">
                      {medicationRecord.drug_form}
                    </Descriptions.Item>
                    <Descriptions.Item label="Drug Name">
                      {medicationRecord.drug_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Drug Strength">
                      {medicationRecord.drug_strength}
                    </Descriptions.Item>
                    <Descriptions.Item label="End Date">
                      {medicationRecord.end_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Frequency">
                      {medicationRecord.frequency}
                    </Descriptions.Item>
                    <Descriptions.Item label="Instructions">
                      {medicationRecord.instructions}
                    </Descriptions.Item>
                    <Descriptions.Item label="Intake Method">
                      {medicationRecord.intake_method}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prescribed On">
                      {medicationRecord.prescribed_on}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prescribed By">
                      {medicationRecord.prescribed_by}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start Date">
                      {medicationRecord.start_date}
                    </Descriptions.Item>
                  </Descriptions>
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Modal>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default Medications;
