import React, { useState, Fragment } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import { EyeOutlined } from "@ant-design/icons";
import {Space, Table, Modal, Steps, Layout } from "antd";


import MedicationForm from "../../medications/medication-form.component";
import { DataPreview } from "../../../Pages/external-appointments/callFeedback.component";

const { Step } = Steps;

const { Footer } = Layout;

const steps = [
  {
    title: 'Consultation Information',
    content: 'First-content',
  },
  {
    title: 'Prescriptions',
    content: 'Second-content',
  },
  {
    title: 'Reports',
    content: 'Last-content',
  },
];

const PrescriptionComponent = (props) => {

  const [medications, setMedications] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState("reports");
  const [record, setRecord] = useState([]);

 

  const AppointmentId = props?.match?.params?.id
  ? props.match.params.id
  : props.appt_id;

  const PatientId = props?.patient_id;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

 const next = e => {
    e.preventDefault();
    props.nextStep();
  };

 const back = e => {
    e.preventDefault();
    props.prevStep();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleMedications = (data) => {
    setMedications([data]);
  };

  const medicationColumns = [
    {
      title: "Drug Name",
      render: (record) => `${record.drug_name} - ${record.drug_strength}`,
      key: "drug_name",
    },
    {
      title: "Drug Form",
      dataIndex: "drug_form",
      key: "drug_form",
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setRecord(record);
              // setRecordsToggle(() => false);
              setCategory("medication");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

    return (
      <Fragment>
        <AppBar style={{ background: "#333", marginBottom:"10px" }} position="relative">
          <Toolbar title="Add Prescription">
            <Typography color="inherit" variant="title">
            Prescription
            </Typography>
          </Toolbar> 
         </AppBar>

        <MedicationForm
              onMedicationSubmit={handleMedications}
              appt_id={AppointmentId}
              patient_id={PatientId}
              presciption={"Add Prescription"}
            />
          
            {medications && Object.keys(medications).length !== 0 && (
              <>  
              <h4 style={{ marginLeft: "10px", marginTop: "10px" }}>
              Presciption Details
            </h4>
              <Table
                dataSource={medications}
                columns={medicationColumns}
                rowKey="medication_id"
                pagination={{ position: ["bottomCenter"] }}
              />
              </>
            )}

          <Modal
            title="Details"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
          >
            <DataPreview data={record} type={category} />
          </Modal>

        <br />
        <br />
        {/* <div style={{display: "flex", justifyContent:"space-around", marginBottom: "10px"}}> */}
        <Button
          style={{
            background: "#EE3B55",
            color: "#FFFFFF",
            marginRight: "1em",
            marginBottom: "10px"
          }}
          label="Back"
          variant="outlined"
          size="large"
          onClick={back}
        >
          Back
        </Button>
        <Button
          style={{
            background: "#991A76",
            color: "#FFFFFF",
            marginBottom: "10px"
          }}
          label="Continue"
          size="large"
          variant="outlined"
          onClick={next}
        >
          {" "}
         Continue
        </Button>
        {/* </div> */}

        <Footer >
        <Steps current={props.step}  size="small" >
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      </Footer>
      </Fragment>
    );
  }

export default PrescriptionComponent;
