import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, TimePicker, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const format = "HH:mm";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const options = ["In person", "Online"];
const options2 = ["scheduled", "pending"];

class AppointmentsForm extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/appointments`;

    let data = {
      patient_id: parseInt(this.props.id),
      purpose: values.user["purpose"],
      appt_type: values.user["appt_type"],
      complaints: values.user["complaints"],
      status: values.user["status"],
      boooked_by: localStorage.getItem("username"),
      exfa_name: values.user["exfa_name"],
      facility_name: values.user["facility_name"],
      date: values["datepicker"].format("YYYY-MM-DD"),
      time: values["timepicker"].format("HH:mm"),
    };
    // this.setState({ loading: true });
    this.handleOk();

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          this.setState({ loading: false, visible: false });
          message.success("Appointments Booked");
          this.props.fetchAppointments();
        }
        // else {
        //   this.setState({ loading: false, visible: false });
        //   message.warning("Can't Upload");
        // }
      })
      .catch((err) => {
        this.setState({ loading: false, visible: false });
        message.error("Appointment Not Booked");
      });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Add Appointments
        </Button>
        <Modal
          visible={visible}
          size="lg"
          width={1000}
          title="Book Appointment"
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
            className="appointment-form"
          >
            <Form.Item
              name={["user", "purpose"]}
              label="Purpose"
              rules={[
                {
                  required: true,
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["user", "appt_type"]}
              label="Appointment Type"
              rules={[
                {
                  required: true,
                  type: "string",
                },
              ]}
            >
              <Select allowClear>
                {options.map((val) => {
                  return (
                    <Option key={val} value={val}>
                      {val}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={["user", "complaints"]}
              label="Complaints"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["user", "status"]}
              label="Status"
              rules={[
                {
                  required: true,
                  type: "string",
                },
              ]}
            >
              <Select allowClear>
                {options2.map((val) => {
                  return (
                    <Option key={val} value={val}>
                      {val}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={["user", "exfa_name"]}
              label="Doctor Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["user", "facility_name"]}
              label="Facility Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="datepicker"
              label="Date"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="timepicker"
              label="Time"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: "Please select Time!",
                },
              ]}
            >
              <TimePicker format={format} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AppointmentsForm;
