import React from "react";
import LineChart from "../Linechart/index.js";
import { Row, Col, DatePicker } from "antd";
import "../../vitals/Vital-Scss/respiration.scss";
import moment from "moment";

const { RangePicker } = DatePicker;

export default class Painscaler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temperature: [],
      filteredData: [],
      d: "",
      s: "",
    };
  }

  handleChange = (date, dateString) => {
    this.setState({
      filteredData: dateString[1],
      d: dateString[0],
      s: dateString[1],
    });

    const { d, s } = this.state;
    fetch(
      `http://104.215.179.29/v1/patients/97/vitals/pain_scale_score?startDate=${d}&endDate=${s}`
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          temperature: data.data,
        });
        console.log(data.data);
      });
  };
  render() {
    return (
      <Row gutter={16}>
        <Col span={24} className="date-picker-container">
          <RangePicker
            onChange={(date, dateString) => {
              this.handleChange(date, dateString);
            }}
            defaultValue={[moment(), moment()]}
          />
        </Col>
        <Col span={24} className="bar-chart-col">
          <LineChart temperature={this.state.temperature} />
        </Col>
      </Row>
    );
  }
}
