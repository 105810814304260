import React, { useState, useEffect } from "react";
import { Layout, Menu, Button, Select, Image } from "antd";
import { Table, Divider, Space, Spin, Input } from "antd";
import { Link } from "react-router-dom";
import {
  CalendarOutlined,
  MedicineBoxOutlined,
  NodeIndexOutlined,
  FileDoneOutlined,
  ForkOutlined,
} from "@ant-design/icons";
import "./dashboard.styles.css";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { MdBloodtype, MdEmail, MdLocalPhone } from "react-icons/md";
import { SiRainmeter } from "react-icons/si";
import { ImUsers } from "react-icons/im";
import { BsFillCalendarDateFill } from "react-icons/bs";
import logo from "../../Thb_Logo.jpeg";
import { GiWeightScale } from "react-icons/gi";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content } = Layout;
const { Column } = Table;
const { Option } = Select;

const Dashboard = () => {
  const [loading, setToggle] = useState(false);
  const [patients, setPatients] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [value, setValue] = useState("");
  const [category, setCategory] = useState("ALL");

  // exclude column list from filter
  const excludeColumns = [
    "blood_glucose",
    "blood_pressure",
    "patient_id",
    "registration_date",
    "signup_code",
    "user_type",
    "weight",
  ];

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setDataSource(patients);
    else {
      const filteredData = patients?.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setDataSource(filteredData);
    }
  };

  const FilterByPhoneInput = () => (
    <Input
      placeholder="Search"
      style={{ width: "auto", float: "right", bottom: "40px" }}
      value={value}
      onChange={(e) => {
        const currValue = e.target.value;
        setValue(currValue);
        filterData(currValue);
        // const filteredData = patients?.filter((entry) =>
        //   entry?.patient_phone?.includes(currValue)
        // );
        // setDataSource(filteredData);
        // console.log(filteredData);
      }}
    />
  );

  const hasSelected = selectedRowKeys.length > 0;

  // const onSelectChange = (selectedRowKeys) => {
  //   console.log("selectedRowKeys changed: ", selectedRowKeys);
  //   setSelectedRowKeys(selectedRowKeys);
  // };

  const { setAuthTokens } = useAuth();

  function logOut() {
    setAuthTokens("");
    localStorage.clear();
  }

  const fetchData = () => {
    const url = `https://app02.thbglobal.com/v1/patients?usertype=healthManager&userid=0`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setPatients(result.data);
          setToggle(true);
        } else {
          setToggle(true);
        }
      });
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const handleCategory = (value) => {
    const data = patients.filter((data) => data.user_type == value);

    setCategoryData(data);
    setCategory(value);
  };

  useEffect(() => {
    fetchData();
    return () => setToggle(false);
  }, []);

  return (
    <div className="dashboard">
      <Layout className="layout">
        <Header className="Header">
          <div
            style={{
              display: "flex",
              fontSize: "medium",
              marginTop: "10px",
            }}
          >
            {/* <img style={{ color: "#fff" }}>THB WEB</img> */}
            {/* <Avatar
              size={{ xl: 80 }}
              shape="square"
              src={
                <Image
                  src={logo}
                  preview={false}
                  style={{ height: "50px", width: "80px" }}
                />
              }
            /> */}
            <Image
              src={logo}
              preview={false}
              style={{ height: "50px", width: "150px" }}
            />
            <Button
              style={{ marginLeft: "auto", marginTop: "10px" }}
              onClick={logOut}
              type="primary"
              size="small"
            >
              Log Out
            </Button>
          </div>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="1" />

          <Content style={{ padding: "0 25px" }}>
            <div className="container">
              <div className="page-title">
                <h1>Dashboard</h1>
              </div>
            </div>
            {/* <div className="site-layout-content">Content</div> */}
            <div className="site-layout-content">
              <p style={{ margin: "15px" }}> Patients</p>
              <Select
                style={{ marginLeft: "15px", width: "120px" }}
                defaultValue="ALL"
                onChange={handleCategory}
              >
                <Option value="ALL">All Users</Option>
                <Option value="Trial">Trial</Option>
                <Option value="Members">Members</Option>
                <Option value="Subscribed">Subscribed</Option>
                <Option value="Corporate">Corporate</Option>
                <Option value="Unverified">Unverified</Option>
              </Select>
              {FilterByPhoneInput()}
              <Divider />
              {loading ? (
                <>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected
                      ? `Selected ${selectedRowKeys.length} items`
                      : ""}
                  </span>
                  <Table
                    dataSource={
                      category === "ALL"
                        ? dataSource
                          ? dataSource
                          : patients
                        : dataSource
                        ? dataSource
                        : categoryData
                    }
                    rowKey="patient_id"
                    size="small"
                    pagination={{ position: ["bottomCenter"] }}
                    // rowSelection={rowSelection}
                  >
                    <Column
                      title={() => <div>Name</div>}
                      dataIndex="patient_name"
                      sorter={(a, b) =>
                        a.patient_name.localeCompare(b.patient_name)
                      }
                      render={(text, record) => {
                        const params = {
                          name: `${record.patient_name}`,
                          email: `${record.patient_email}`,
                          mrn: `${record.patient_membership_number}`,
                          phone: `${record.patient_phone}`,
                        };

                        // console.log(record.patient_id, record.patient_email);
                        return (
                          <Link
                            to={{
                              pathname: `/vitals/${record.patient_id}`,
                              param1: params,
                            }}
                          >
                            {text}
                          </Link>
                        );
                      }}
                    />
                    <Column
                      title={() => (
                        <div>
                          <MdEmail /> Email
                        </div>
                      )}
                      dataIndex="patient_email"
                      // key="patient_email"
                      sorter={(a, b) =>
                        a.patient_email.localeCompare(b.patient_email)
                      }
                    />
                    <Column
                      title="MRN"
                      dataIndex="patient_membership_number"
                      key="patient_membership_number"
                    />
                    <Column
                      title={() => (
                        <div>
                          <MdLocalPhone /> Phone
                        </div>
                      )}
                      dataIndex="patient_phone"
                      key="patient_phone"
                      responsive={["lg"]}
                    />

                    <Column
                      title={() => (
                        <div>
                          <BsFillCalendarDateFill /> Registration Date
                        </div>
                      )}
                      render={(date) =>
                        new Date(date.registration_date).toDateString()
                      }
                      key="registration_date"
                      responsive={["lg"]}
                    />

                    <Column
                      title={() => (
                        <div style={{ fontSize: "12px" }}>
                          <GiWeightScale /> <strong>Weight Scale</strong>
                        </div>
                      )}
                      // dataIndex="weight"
                      key="weight"
                      render={(record) => {
                        if (record.weight) {
                          return `${record.weight} kg`;
                        } else {
                          return "N/A";
                        }
                      }}
                    />
                    <Column
                      title={() => (
                        <div style={{ fontSize: "12px" }}>
                          <MdBloodtype /> <strong>Blood Pressure</strong>
                        </div>
                      )}
                      // dataIndex="blood_pressure"
                      key="bp"
                      render={(record) => {
                        if (record.blood_pressure[1]) {
                          return `${record.blood_pressure[1]} / ${record.blood_pressure[0]} mmHg`;
                        } else {
                          return "N/A";
                        }
                      }}
                    />

                    <Column
                      title={() => (
                        <div style={{ fontSize: "12px" }}>
                          <SiRainmeter />
                          <strong> Glucose</strong>
                        </div>
                      )}
                      // dataIndex="blood_glucose"
                      key="bg"
                      render={(record) => {
                        if (record.blood_glucose) {
                          return `${record.blood_glucose} mmol/L`;
                        } else {
                          return "N/A";
                        }
                      }}
                    />
                    <Column
                      title={() => (
                        <div>
                          <ImUsers /> User Type
                        </div>
                      )}
                      dataIndex="user_type"
                      key="status"
                      // render={(record) => {
                      //   return (
                      //     <h1>{record}</h1>
                      //   );
                      // }}
                    />

                    <Column
                      title="Action"
                      key="action"
                      render={(record) => {
                        const params = {
                          name: `${record.patient_name}`,
                          email: `${record.patient_email}`,
                          mrn: `${record.patient_membership_number}`,
                          phone: `${record.patient_phone}`,
                        };

                        return (
                          <Space size="middle">
                            <Link
                              to={{
                                pathname: `/medicalreports/${record.patient_id}`,
                                param1: params,
                              }}
                            >
                              {" "}
                              <span>
                                <FileDoneOutlined title="Medical Reports" />
                              </span>
                            </Link>
                            <Link
                              to={{
                                pathname: `/medications/${record.patient_id}`,
                                param1: params,
                              }}
                            >
                              <span>
                                <MedicineBoxOutlined title="Medications" />
                              </span>
                            </Link>
                            <Link
                              to={{
                                pathname: `/appointments/${record.patient_id}`,
                                param1: params,
                              }}
                            >
                              {" "}
                              <span>
                                {" "}
                                <CalendarOutlined title="Appointments" />
                              </span>
                            </Link>
                            <Link
                              to={{
                                pathname: `/vitals/${record.patient_id}`,
                                param1: params,
                              }}
                            >
                              {" "}
                              <span>
                                <NodeIndexOutlined title="Vitals" />
                              </span>
                            </Link>
                            <Link
                              to={{
                                pathname: `/clinicalvisits/${record.patient_id}`,
                                param1: params,
                              }}
                            >
                              {" "}
                              <span>
                                <ForkOutlined title="Clinal Visits" />
                              </span>
                            </Link>
                          </Space>
                        );
                      }}
                    />
                  </Table>
                </>
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
            </div>
          </Content>
        </Layout>
        {/* <Footer style={{ textAlign: "center" }}></Footer> */}
      </Layout>
    </div>
  );
};
export default Dashboard;
