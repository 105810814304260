import React from "react";
import LineChart from "../Linechart/index.js";
import { Row, Col, DatePicker, Radio, Spin, Divider } from "antd";
import "../../vitals/Vital-Scss/respiration.scss";
import { subDays, format } from "date-fns";
import VitalsTable from "./table.js";

const { RangePicker } = DatePicker;

export default class Bca extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bca: [],
      filteredData: [],
      d: "",
      s: "",
      loader: true,
    };
  }

  handleChange = (date, dateString) => {
    this.setState(
      {
        filteredData: dateString[1],
        d: dateString[0],
        s: dateString[1],
        loader: false,
      },
      () => {
        const { d, s } = this.state;
        const pid = this.props.p_id;
        console.log(d);
        fetch(
          `https://app02.thbglobal.com/v1/patients/${pid}/vitals/bca?startDate=${d}&endDate=${s}`,
          {
            headers: {
              "x-api-key": `Bearer ${JSON.parse(
                localStorage.getItem("tokens")
              )}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) =>
            this.setState({
              bca: data.data,
              loader: true,
            })
          );
      }
    );
  };

  onChange = (e) => {
    console.log("radio checked", e.target.value);
    // this.setState({value:e.target.value});
    const today = format(new Date(), "yyyy-MM-dd");
    if (e.target.value == 1) {
      let past_week = format(subDays(new Date(), 7), "yyyy-MM-dd");
      this.handleChange("", [past_week, today]);
    } else if (e.target.value == 2) {
      let past_month = format(subDays(new Date(), 30), "yyyy-MM-dd");
      this.handleChange("", [past_month, today]);
    } else {
      let past_year = format(subDays(new Date(), 365), "yyyy-MM-dd");
      this.handleChange("", [past_year, today]);
    }
  };
  componentDidMount() {
    const today = format(new Date(), "yyyy-MM-dd");
    let past_month = format(subDays(new Date(), 30), "yyyy-MM-dd");
    this.handleChange("", [past_month, today]);
  }

  render() {
    sessionStorage.setItem("activeTab", 10);
    const data = [...this.state.bca];
    return (
      <>
        <Row gutter={16}>
          <Col span={24} className="date-picker-container">
            <RangePicker
              onChange={(date, dateString) => {
                this.handleChange(date, dateString);
              }}
            />
            <hr />
            <Radio.Group
              onChange={this.onChange}
              value={this.value}
              style={{
                background: "#08aca4",
                color: "white",
                borderRadius: "12px",
                padding: "8px",
              }}
            >
              <Radio value={1}>Week</Radio>
              <Radio value={2}>Month</Radio>
              <Radio value={3}>Year</Radio>
            </Radio.Group>
          </Col>
          <Divider />
          {this.state.loader ? (
            <Col span={24} className="line-chart-col">
              <LineChart data={data} />
            </Col>
          ) : (
            <Spin
              size="large"
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          )}
        </Row>
        <Divider />
        <VitalsTable vitalsLoader={this.state.loader} data={data} />
      </>
    );
  }
}
