import React from "react";
import { Link } from "react-router-dom";
import "./homepage.styles.css";

const Homepage = () => (
  <div className="homepage">
    <div className="top-right links">
      <Link className="option" to="/login">
        SIGN IN
      </Link>
    </div>
    <div className="title m-b-md">WELCOME</div>
  </div>
);

export default Homepage;
