import { Modal, Button } from "antd";
import { Form, DatePicker, TimePicker, Select, message, Radio } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const children = [];
const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
for (let i = 1; i < 8; i++) {
  children.push(<Option key={i}>{Days[i - 1]}</Option>);
}

const doctorId = localStorage.getItem("userid");

class ConsultationForm extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
    startDate: moment(),
    value: 1,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleDate = (value) => {
    this.setState({ startDate: value });
  };

  handleValue = (event) => {
    this.setState({ value: event.target.value });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/doctors/${doctorId}/slots`;
    let data;
    if (this.state.value === 1) {
      data = {
        start_date: values["start-date-picker"].format("YYYY-MM-DD"),
        end_date: values["end-date-picker"].format("YYYY-MM-DD"),
        start_time: values["start-time-picker"].format("HH:mm"),
        end_time: values["end-time-picker"].format("HH:mm"),
        duration: values["duration-picker"].format("mm"),
        interval: values["interval-picker"].format("mm"),
        days: values.user.days,
        slot_status: "available",
      };
    } else if (this.state.value === 2) {
      data = {
        start_time: values["start-time-picker"].format("HH:mm"),
        end_time: values["end-time-picker"].format("HH:mm"),
        // slot_status: "available",
        slot_date: values["slot-date-picker"].format("YYYY-MM-DD"),
      };
    }

    this.handleOk();

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          this.setState({ loading: false, visible: false });
          message.success("Consultation Slot Created");
          this.props.onFinish(moment());
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Unable to create Slot");
      });
  };

  render() {
    const { visible, loading, startDate } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Create Slots
        </Button>

        <Modal
          visible={visible}
          title="Create Consultation Slots"
          onOk={this.handleOk}
          width={450}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
          >
            <Form.Item>
              <Radio.Group onChange={this.handleValue} value={this.state.value}>
                <Radio value={1}>Continuous</Radio>
                <Radio value={2}>Single</Radio>
              </Radio.Group>
            </Form.Item>

            {this.state.value === 1 && (
              <>
                <Form.Item
                  name="start-date-picker"
                  label="Start Date"
                  initialValue={moment()}
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: "Please select start date!",
                    },
                  ]}
                >
                  <DatePicker onChange={this.handleDate} />
                </Form.Item>

                <Form.Item
                  name="end-date-picker"
                  label="End Date"
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: "Please select end date!",
                    },
                  ]}
                >
                  <DatePicker
                  // disabledDate={(current) => {
                  //   return (
                  //     current &&
                  //     current <= moment(startDate, "YYYY-MM-DD").add(7, "days")
                  //   );
                  // }}
                  />
                </Form.Item>

                <Form.Item
                  name="duration-picker"
                  label="Duration"
                  rules={[
                    {
                      required: true,
                      message: "Please select duration!",
                    },
                  ]}
                >
                  <TimePicker
                    format="mm"
                    minuteStep={5}
                    placeholder="Minutes"
                  />
                </Form.Item>
                <Form.Item
                  name="interval-picker"
                  label="Interval"
                  //   initialValue={moment()}
                  rules={[
                    {
                      required: true,
                      message: "Please select interval!",
                    },
                  ]}
                >
                  <TimePicker
                    format="mm"
                    minuteStep={5}
                    placeholder="Minutes"
                  />
                </Form.Item>

                <Form.Item
                  name={["user", "days"]}
                  label="Select Days"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                  >
                    {children}
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item
              name="start-time-picker"
              label="Start Time"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: "Please select start time!",
                },
              ]}
            >
              <TimePicker format="HH:mm" minuteStep={5} />
            </Form.Item>

            <Form.Item
              name="end-time-picker"
              label="End Time"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: "Please select end time!",
                },
              ]}
            >
              <TimePicker format="HH:mm" minuteStep={5} />
            </Form.Item>

            {this.state.value === 2 && (
              <Form.Item
                name="slot-date-picker"
                label="Slot Date"
                initialValue={moment()}
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: "Please select slot date!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ConsultationForm;
