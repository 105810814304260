import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Spin, Descriptions } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import "../appointments/appointments.styles.css";
import { ExtraDetails } from "../external-clinicalvisits/externalclinicalvisits";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ClinicalVisits = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [medications, setMedications] = useState([]);
  const [reports, setReports] = useState([]);
  const [record, setRecord] = useState({});
  const [medicationRecord, setMedicationRecord] = useState({});
  const [reportsRecord, setReportsRecord] = useState({});
  const [mime, setMime] = useState("");
  const [filecontent, setFileContent] = useState("");
  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState("reports");

  const PatientId = props.match.params.id;
  const PatientName = props.location?.param1?.name?.replace("undefined", "");
  const PatientEmail = props.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var params = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };

  const columns = [
    {
      title: "Date",
      render: (record) => record.appointment_slot_info.slot_date,
      key: "date",
      sorter: (a, b) =>
        moment(a.appointment_slot_info.slot_date).unix() -
        moment(b.appointment_slot_info.slot_date).unix(),
    },

    {
      title: "Appointment Time",
      render: (record) =>
        `${record.appointment_slot_info.start_time} - ${record.appointment_slot_info.end_time}`,
      key: "start_time",
    },

    {
      title: "Facility Name",
      render: (record) => record.appointment_info.facility_name,
      key: "appointment_id",
    },
    {
      title: "Doctor Name",
      render: (record) => record.appointment_provider_info.provider_name,
      key: "doctor_name",
    },
    {
      title: "Purpose",
      render: (record) => record.appointment_info.purpose,
      key: "purpose",
    },

    {
      title: "Status",
      render: (record) => record.appointment_info.status,
      key: "status",
    },

    // {
    //   title: "Start Time",
    //   render: (record) => record.appointment_slot_info.start_time,
    //   key: "start_time",
    // },
    // {
    //   title: "End Time",
    //   render: (record) => record.appointment_slot_info.end_time,
    //   key: "end_time",
    // },

    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              // setRecordsToggle(true);
              setShowTable(false);
              setRecord(record);
              if (record.appointment_info.status === "Attended") {
                fetchAppointmentsMedications(
                  record.appointment_info.patient_id,
                  record.appointment_info.appointment_id
                );
                fetchAppointmentsReports(
                  record.appointment_info.patient_id,
                  record.appointment_info.appointment_id
                );
              }
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const reportsColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Doctor",
      dataIndex: "prescribed_by",
      key: "prescribed_by",
    },
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: "Uploaded On",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              setReportsRecord(record);
              setCategory("reports");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const medicationColumns = [
    {
      title: "Drug Name",
      render: (record) => `${record.drug_name} - ${record.drug_strength}`,
      key: "drug_name",
    },
    {
      title: "Drug Form",
      dataIndex: "drug_form",
      key: "drug_form",
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setMedicationRecord(record);
              setCategory("medication");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = () => {
    const url = `https://app02.thbglobal.com/v1/appointmentsStatus/clinicalVisits?userId=${PatientId}&userType=patients`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message === "Success") {
          setAppointments(result.data);
          setReportsToggle(true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  const fetchAppointmentsMedications = (Id, appt_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${Id}/prescriptions?apptId=${appt_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setMedications(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
        }
      });
  };

  const fetchAppointmentsReports = (Id, appt_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${Id}/medicalReports?apptId=${appt_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          console.log("Yo enterd");
          setReports(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
        }
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(props);
  return (
    <div className="appointments">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["3"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 10px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>ClinicalVisits</h1>
              </div>
            </div>
            <div className="site-layout-content">
              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              {!showTable && (
                <ArrowLeftOutlined
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setShowTable(true);
                    setRecordsToggle(false);
                    setRecord([]);
                    setMedications([]);
                    setReports([]);
                  }}
                />
              )}
              <Divider />
              {reportsLoader ? (
                showTable && (
                  <Table
                    dataSource={appointments}
                    columns={columns}
                    pagination={{ position: ["bottomCenter"] }}
                    rowKey={appointments[0]?.appointment_info.appointment_id}
                  />
                )
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}

              {!showTable ? (
                <>
                  <Descriptions
                    bordered
                    title="Appointment"
                    size="default"
                    column={1}
                    labelStyle={{ backgroundColor: "#dad2d2" }}
                  >
                    <Descriptions.Item label="Appointment Id">
                      {record.appointment_info.appointment_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Appointments Type">
                      {record.appointment_info.appt_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Facility Name">
                      {record.appointment_info.facility_name}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Doctor Slot Id">
                      {record.appointment_info.doctor_slot_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payment Id">
                      {record.appointment_info.payment_id}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Purpose">
                      {record.appointment_info.purpose}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {record.appointment_info.status}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Provider Id">
                      {record.appointment_provider_info.provider_id}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Provider Name">
                      {record.appointment_provider_info.provider_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Provider Type">
                      {record.appointment_provider_info.provider_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start Time">
                      {record.appointment_slot_info.start_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="End Time">
                      {record.appointment_slot_info.end_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="Slot Date">
                      {record.appointment_slot_info.slot_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Complaints">
                      {record.appointment_info.complaints}
                    </Descriptions.Item>
                    <Descriptions.Item label="Consultation Notes">
                      {record.appointment_info.remarks}
                    </Descriptions.Item>
                  </Descriptions>

                  <Divider />
                  {recordsLoader ? (
                    <>
                      <h4 style={{ marginLeft: "10px" }}>Medications</h4>
                      {medications && medications.length > 0 && (
                        <Table
                          dataSource={medications}
                          columns={medicationColumns}
                          rowKey="medication_id"
                          pagination={{ position: ["bottomCenter"] }}
                        />
                      )}
                      <Divider />
                      <h4 style={{ marginLeft: "10px" }}>Medical Reports</h4>
                      {reports && reports.length > 0 && (
                        <Table
                          dataSource={reports}
                          columns={reportsColumns}
                          pagination={{ position: ["bottomCenter"] }}
                        />
                      )}
                    </>
                  ) : (
                    <Spin
                      size="large"
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  )}
                </>
              ) : null}

              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {!showTable && (
                  <ExtraDetails
                    record={record}
                    medicationRecord={medicationRecord}
                    reportsRecord={reportsRecord}
                    setVisible={setVisible}
                    setFileContent={setFileContent}
                    setMime={setMime}
                    setTitle={setTitle}
                    mime={mime}
                    filecontent={filecontent}
                    title={title}
                    visible={visible}
                    type={category}
                  />
                )}
              </Modal>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default ClinicalVisits;
