import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import { RiHeartPulseFill } from "react-icons/ri";
import { FaTemperatureLow } from "react-icons/fa";
import {
  GiBodyHeight,
  GiWeightScale,
  GiNightSleep,
  GiFat,
} from "react-icons/gi";
import { IoPulse, IoFootstepsSharp } from "react-icons/io5";
import { SiOxygen } from "react-icons/si";
import { MdBloodtype } from "react-icons/md";
import { SiRainmeter } from "react-icons/si";

import { Statistic, Card, Row, Col } from "antd";

import { UserOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
// import "./externalUserManagement.styles.css";
import ExternalUserForm from "../../Components/externaluser/externalUserForm.component";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const VitalsDashboard = (props) => {
  const [loader, setDataToggle] = useState(false);
  const [vitals, setVitals] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const PatientId = props.match.params.id;
  const PatientName = props.location?.param1?.name.replace("undefined", "");
  const PatientEmail = props.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var params = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const fetchVitalsData = () => {
    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}/vitalsPreview`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setVitals(result.data);

          setDataToggle(() => true);
        } else {
          setDataToggle(true);
        }
      });
  };

  useEffect(() => {
    fetchVitalsData();
  }, []);

  return (
    <div className="internal-user">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["4", "s2"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1", "4"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 10px" }}>
            <div className="user-container">
              <div className="page-title">
                <h1> Vitals Dashboard </h1>
              </div>
            </div>
            <div className="site-layout-content">
              {/* <ExternalUserForm/> */}

              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              <Divider />
              {loader ? (
                <Row gutter={16}>
                  {Object.keys(vitals).map((key) => {
                    let new_key = "";
                    let new_value;
                    let icon = <RiHeartPulseFill />;
                    if (key === "bloodpressure") {
                      new_key = "BLOOD PRESSURE";
                      new_value = vitals[key]?.vital_reading?.replace(/,/, "/");
                      icon = <MdBloodtype />;
                    } else if (key === "bloodsugar") {
                      new_key = "BLOOD SUGAR";
                      icon = <SiRainmeter />;
                    } else if (key === "oxygen_saturation") {
                      new_key = "OXYGEN SATURATION";
                      icon = <SiOxygen />;
                    } else if (key === "pulse") {
                      new_value = parseInt(vitals[key].vital_reading);
                      icon = <IoPulse />;
                    } else if (key === "temperature") {
                      icon = <FaTemperatureLow />;
                    } else if (key === "height") {
                      icon = <GiBodyHeight />;
                    } else if (key === "weight") {
                      icon = <GiWeightScale />;
                    } else if (key === "steps") {
                      icon = <IoFootstepsSharp />;
                    } else if (key === "sleep") {
                      icon = <GiNightSleep />;
                    } else if (key === "fat") {
                      icon = <GiFat />;
                    } else if (key === "sleep") {
                      if (vitals[key].vital_reading) {
                        new_value = vitals[key].vital_reading + " hrs";
                      }
                    }

                    return (
                      <Col span={6}>
                        <Card
                          title={new_key ? new_key : key.toUpperCase()}
                          bordered={true}
                        >
                          {icon}
                          <Statistic
                            value={
                              new_value ? new_value : vitals[key].vital_reading
                            }
                            // precision={2}
                            valueStyle={{ color: "#00008B" }}
                            // prefix={<ArrowUpOutlined />}
                            suffix={vitals[key].vital_unit}
                          />
                          {vitals[key].vital_date}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "right" }}> */}
          {/* <MedicationForm id={pid} /> */}
          {/* </Footer> */}
        </Layout>
      </Layout>
    </div>
  );
};
export default VitalsDashboard;
