import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const children = [];
const Services = {
  1: "Global Care",
  3: "Home Nursing",
  4: "Connected Care",
  5: "24/7 Doctor",
  6: "Health Manager",
  7: "EHR",
};

for (const item in Services) {
  children.push(<Option key={item}>{Services[item]}</Option>);
}

class SubscriptionForm extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/signupCode`;

    const serviceIds = values.user.service_id.map(Number);

    const data = {
      organization: values.user.organization,
      code: values.user.code,
      start_date: values["start-date-picker"].format("YYYY-MM-DD"),
      end_date: values["end-date-picker"].format("YYYY-MM-DD"),
      head_count: 0,
      service_id: serviceIds,
      status: "Active",
    };

    this.handleOk();

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          message.success("Subscription Data Created");
          this.props.fetchSignUpData();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Subscription Data Not Created");
        console.log(err);
      });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Subscriptions
        </Button>

        <Modal
          visible={visible}
          title="Set Subscriptions"
          onOk={this.handleOk}
          width={1000}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
          >
            <Form.Item
              name={["user", "organization"]}
              label="Organization"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["user", "code"]}
              label="Code"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="start-date-picker"
              label="Start Date"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please Select Start Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="end-date-picker"
              label="End Date"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please Select End Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name={["user", "service_id"]}
              label="Select Sevices"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
              >
                {children}
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default SubscriptionForm;
