import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Button, Descriptions, Spin } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space, message } from "antd";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import SubscriptionForm from "../../Components/subscription-management/subscriptionForm.component";
import UpdateSubscription from "../../Components/subscription-management/updateSubscription.component";
import "../appointments/appointments.styles.css";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content } = Layout;

const Services = {
  1: "Global Care",
  3: "Home Nursing",
  4: "Connected Care",
  5: "24/7 Doctor",
  6: "Health Manager",
  7: "EHR",
};

const Subscriptions = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [signUpData, setSignUpData] = useState([]);
  const [signUpRecords, setSignUpDataById] = useState([]);
  const [pid, setId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const PatientId = props.match.params.id;

  const columns = [
    {
      title: "Orgainzation",
      dataIndex: "organization",
      key: "organization",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Head Count",
      dataIndex: "head_count",
      key: "head_count",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <EyeOutlined
            title="See Records"
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              getSignUpDataById(record.id);
              setRecordsToggle(false);
              showModal();
            }}
          />

          <UpdateSubscription
            sc_id={record.id}
            organization={record.organization}
            code={record.code}
            start_date={record.start_date}
            end_date={record.end_date}
            service_id={record.service_id}
            fetchSignUpData={fetchSignUpData}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchSignUpData();
    setId(PatientId);
  }, []);

  const fetchSignUpData = () => {
    const url = `https://app02.thbglobal.com/v1/signupCode`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setSignUpData(result.data);
          setReportsToggle(true);
        } else {
          message.warning("Can't load the data.");
          setReportsToggle(true);
        }
      });
  };

  const getSignUpDataById = (signup_id) => {
    const url = `https://app02.thbglobal.com/v1/signupCode/${signup_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setSignUpDataById(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
          message.warning("Can't load the data.");
        }
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="health-board">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="7" />
          <Content style={{ padding: "0 50px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>SignUp Code</h1>
              </div>
              <SubscriptionForm id={pid} fetchSignUpData={fetchSignUpData} />
            </div>
            <div className="site-layout-content">
              <Divider />
              {reportsLoader ? (
                <Table
                  dataSource={Object.values(signUpData)}
                  columns={columns}
                  rowKey={signUpData}
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
              {/* <h1 style={{ marginLeft: "10px" }}>Select Patient</h1>*/}

              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {recordsLoader ? (
                  <Descriptions
                    bordered
                    title="SignUp Code Details"
                    size="large"
                    column={1}
                    labelStyle={{ backgroundColor: "#dad2d2", width: "125px" }}
                  >
                    <Descriptions.Item label="Organization">
                      {signUpRecords.organization}
                    </Descriptions.Item>
                    <Descriptions.Item label="Code">
                      {signUpRecords.code}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start Date">
                      {signUpRecords.start_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="End Date">
                      {signUpRecords.end_date}
                    </Descriptions.Item>

                    <Descriptions.Item label="Head Count">
                      {signUpRecords.head_count}
                    </Descriptions.Item>
                    <Descriptions.Item label="Services">
                      {Services[signUpRecords.service_id]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {signUpRecords.status}
                    </Descriptions.Item>
                  </Descriptions>
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Modal>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "right" }}>
        </Footer>
    */}
        </Layout>
      </Layout>
    </div>
  );
};
export default Subscriptions;
