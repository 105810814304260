import React, { useState, useEffect } from "react";
import { Layout, Menu, Spin } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider } from "antd";

import { UserOutlined } from "@ant-design/icons";
import "./externalUserManagement.styles.css";
import ExternalUserForm from "../../Components/externaluser/externalUserForm.component";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content } = Layout;

const ExternalUserManagement = (props) => {
  const [usersLoader, setUsersToggle] = useState(false);
  const [users, setUsers] = useState([]);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
    },
    {
      title: "Patient Email",
      dataIndex: "patient_email",
      key: "patient_email",
    },
    {
      title: "MRN",
      dataIndex: "patient_membership_number",
      key: "patient_membership_number",
    },
    {
      title: "Phone Number",
      dataIndex: "patient_phone",
      key: "patient_phone",
    },
    {
      title: "Weight",
      key: "weight",
      render: (record) => {
        if (record.weight) {
          return `${record.weight} kg`;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Blood Pressure",
      key: "bp",
      render: (record) => {
        if (record.blood_pressure[1]) {
          return `${record.blood_pressure[1]} / ${record.blood_pressure[0]} mmHg`;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Blood Glucose",
      key: "bg",
      render: (record) => {
        if (record.blood_glucose) {
          return `${record.blood_glucose} mmol/L`;
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "status",
      // render={(record) => {
      //   return (
      //     <h1>{record}</h1>
      //   );
      // }}
    },
  ];

  const fetchExternalUserManagementData = () => {
    const url = `https://app02.thbglobal.com/v1/patients?usertype=healthManager&userid=0`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setUsers(result.data);
          setUsersToggle(true);
        } else {
          setUsersToggle(true);
        }
      });
  };

  // const getExternalUserManagementById = (userId) => {
  //   const url = `https://app02.thbglobal.com/v1/internalUserManagement/${category}/${userId}`;

  //   fetch(url, {
  //     headers: {
  //       "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.type === "Success") {
  //         setUsers(result.data);
  //         setUserToggle(() => true);
  //       } else {
  //         setUserToggle(true);
  //       }
  //     });
  // };

  useEffect(() => {
    fetchExternalUserManagementData();
  }, []);

  return (
    <div className="internal-user">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent defaultOpenKeys="sub1" selectedKey="5" />
          <Content style={{ padding: "0 50px" }}>
            <div className="user-container">
              <div className="page-title">
                <h1> External User Management </h1>
              </div>
            </div>
            <div className="site-layout-content">
              <ExternalUserForm
                fetchExternalUserManagementData={
                  fetchExternalUserManagementData
                }
              />
              <Divider />
              {usersLoader ? (
                <Table
                  dataSource={users}
                  columns={columns}
                  rowKey="patient_id"
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default ExternalUserManagement;
