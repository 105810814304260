import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space, Descriptions, message } from "antd";
import moment from "moment";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import InputForm from "../../Components/medicalreports/form.component";
import "./medical-reports.styles.css";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const MedicalReports = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [reports, setReports] = useState([]);
  const [medicalRecords, setRecords] = useState([]);
  const [pid, setId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mime, setMime] = useState("");
  const [filecontent, setFileContent] = useState("");
  const [title, setTitle] = useState("");

  const PatientId = props.match.params.id;
  const PatientName = props?.location?.param1?.name?.replace("undefined", "");
  const PatientEmail = props?.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var params = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };
  const columns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Doctor",
      dataIndex: "prescribed_by",
      key: "prescribed_by",
    },
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: "Document Date",
      dataIndex: "generation_date",
      key: "generation_date",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              getRecords(record.patient_id, record.medical_record_id);
              setRecordsToggle(false);
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchReports();
    setId(PatientId);
  }, []);

  const fetchReports = () => {
    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}/medicalReports`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setReports(result.data);
          setReportsToggle(true);
        } else {
          setReportsToggle(true);
          message.warning("Can't load the data.");
        }
      });
  };

  const getRecords = (p_id, m_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${p_id}/medicalReports/${m_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setRecords(result.data);
          setRecordsToggle(true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // function onChange(value) {
  //   console.log(`${value}`);
  //   fetchReports(value);
  //   console.log({ reports });
  //   setId(value);
  // }

  function downloadPDF(filecontent, filename) {
    // const linkSource = `data:application/pdf;base64,${pdf}`;
    // const downloadLink = document.createElement("a");
    // const fileName = "abc.pdf";
    // downloadLink.href = linkSource;
    // downloadLink.target = "_blank";
    // downloadLink.download = filename;
    // downloadLink.click();
    // window.open(linkSource, "_blank");
    var w = window.open("about:blank");

    var signatures = {
      JVBERi0: "application/pdf",
      "/9j/": "image/jpeg",
      iVBORw0KGgo: "image/png",
    };

    function detectMimeType(b64) {
      for (var s in signatures) {
        if (b64.indexOf(s) === 0) {
          return signatures[s];
        }
      }
    }

    let mimeType = detectMimeType(filecontent);

    setTimeout(function () {
      //FireFox seems to require a setTimeout for this to work.
      // w.document.body.appendChild(
      w.document.write(
        "<iframe width='100%' height='100%' src='data:" +
          mimeType +
          ";base64, " +
          filecontent +
          // encodeURI(filecontent) +
          "'></iframe>"
      );
    }, 0);
  }

  return (
    <div className="medical-reports">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["6"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 50px" }}>
            <div className="medicalreports-container">
              <div className="page-title">
                <h1>Medical Reports</h1>
              </div>
              <InputForm id={pid} fetchReports={fetchReports} />
            </div>
            <div className="site-layout-content">
              {/* <span> Patients Details</span> */}
              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              <Divider />
              {reportsLoader ? (
                <Table
                  dataSource={reports}
                  columns={columns}
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
              {/* <h1 style={{ marginLeft: "10px" }}>Select Patient</h1>*/}

              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {recordsLoader ? (
                  <Descriptions
                    bordered
                    title="Medical Report"
                    size="large"
                    column={1}
                    // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    labelStyle={{ backgroundColor: "#dad2d2" }}
                  >
                    <Descriptions.Item label="Record Name">
                      {medicalRecords.file_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="File Name">
                      {medicalRecords.record_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Category">
                      {medicalRecords.category}
                    </Descriptions.Item>
                    <Descriptions.Item label="Document Date">
                      {medicalRecords.generation_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prescribed By">
                      {medicalRecords.prescribed_by}
                    </Descriptions.Item>
                    <Descriptions.Item label="Facility Name">
                      {medicalRecords.facility_name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Uploaded On">
                      {medicalRecords.created_at}
                    </Descriptions.Item>
                    <Descriptions.Item label="Uploaded By">
                      {medicalRecords.upoaded_by}
                    </Descriptions.Item>
                    <Descriptions.Item label="File Content">
                      {
                        <a
                          type="primary"
                          size="small"
                          href={`${medicalRecords.file_content}`}
                          target="_blank"
                          // onClick={
                          // () => {
                          // var signatures = {
                          //   JVBERi0: "application/pdf",
                          //   "/9j/": "image/jpeg",
                          //   iVBORw0KGgo: "image/png",
                          // };

                          // function detectMimeType(b64) {
                          //   for (var s in signatures) {
                          //     if (b64.indexOf(s) === 0) {
                          //       return signatures[s];
                          //     }
                          //   }
                          // }
                          // setFileContent(medicalRecords.file_content);
                          // setTitle(medicalRecords.file_name);
                          // let mimeType = detectMimeType(
                          //   medicalRecords.file_content
                          // );
                          // setMime(mimeType);
                          //   setVisible(true);
                          // }
                          // downloadPDF(
                          //   medicalRecords.file_content,
                          //   medicalRecords.file_name
                          // )
                          // }
                        >
                          View File
                        </a>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Notes"
                      // contentStyle={{ width: "10px" }}
                    >
                      {medicalRecords.notes}
                    </Descriptions.Item>
                  </Descriptions>
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Modal>

              <Modal
                title={title}
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
              >
                <iframe
                  style={{ width: "100%", height: "100vh" }}
                  name="myIframe"
                  // src={`data:${mime};base64,${filecontent}`}
                  // src={`${filecontent}`}
                ></iframe>
              </Modal>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default MedicalReports;
