import React, { useState, useEffect } from "react";
import { Layout, Menu, Spin, message } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table, Divider, Space } from "antd";

import {
  UserOutlined,
  EyeOutlined,
  CloseCircleFilled,
  CheckCircleTwoTone,
  VideoCameraTwoTone,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./externalAppointments.styles.css";
import ExternalAppointmentsDetails from "./externalAppointmentsDetails.component";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content, Footer } = Layout;

const ExternalAppointments = (props) => {
  const [externalReportsLoader, setExternalReportsToggle] = useState(false);
  const [externalAppointments, setExternalAppointments] = useState([]);
  const [record, setRecord] = useState({});
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [value, setValue] = useState("");
  const [appt_id, setAppointment] = useState();
  const [status, setStatus] = useState();
  const [providerNotes, setProviderNotes] = useState();

  const PatientId = localStorage.getItem("userid");
  const columns = [
    {
      title: "Patient Name",
      render: (record) => (
        <Link to={`/vitals/${record.appointment_info.patient_id}`}>
          {record.appointment_provider_info.provider_name}
        </Link>
      ),
      key: "provider_name",
    },

    {
      title: "Purpose",
      render: (record) => record.appointment_info.purpose,
      key: "purpose",
    },

    {
      title: "Date",
      render: (record) => record.appointment_slot_info.slot_date,
      key: "date",
    },
    {
      title: "Start Time",
      render: (record) => record.appointment_slot_info.start_time,
      key: "date",
    },
    {
      title: "Status",
      render: (record) => record.appointment_info.status,
      key: "status",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setStatus(record.appointment_info.status);
              fetchParticularAppointments(
                record.appointment_info.appointment_id
              );
              setShowTable(false);
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
    {
      key: (record) => record.appointment_info.appointment_id,
      render: (record) => {
        if (record.appointment_info.status === "Pending") {
          return (
            <Space size="middle">
              <CheckCircleTwoTone
                title="Accept"
                name={`${record.appointment_info.appointment_id}accept`}
                key={record.appointment_info.appointment_id}
                onClick={(e) => {
                  const ele = document.getElementById(
                    record.appointment_info.appointment_id
                  );
                  ele.style.display = "block";
                  // debugger;
                  // setLoader(false);

                  axios
                    .put(
                      `https://app02.thbglobal.com/v1/appointments/${record.appointment_info.appointment_id}`,
                      {
                        status: "scheduled",
                      },
                      {
                        headers: {
                          "x-api-key": `Bearer ${localStorage.getItem(
                            "tokens"
                          )}`,
                        },
                      }
                    )
                    .then((result) => {
                      if (result.data.type === "Success") {
                        message.success("Appointment Scheduled");
                        // setLoader(true);
                        document.getElementById(
                          record.appointment_info.appointment_id
                        ).style.display = "none";

                        fetchExternalAppointments();
                      } else {
                        message.warning("Can't Update Request");
                      }
                    })
                    .catch((e) => {
                      message.error("Request Failed");
                      document.getElementById(
                        record.appointment_info.appointment_id
                      ).style.display = "none";
                    });
                }}
                twoToneColor="#52c41a"
              />
              <CloseCircleFilled
                title="Reject"
                key={record.appointment_info.appointment_id}
                name={`${record.appointment_info.appointment_id}reject`}
                onClick={(item) => {
                  const ele = document.getElementById(
                    record.appointment_info.appointment_id
                  );
                  ele.style.display = "block";

                  axios
                    .put(
                      `https://app02.thbglobal.com/v1/appointments/${record.appointment_info.appointment_id}`,
                      {
                        status: "cancelled",
                      },
                      {
                        headers: {
                          "x-api-key": `Bearer ${localStorage.getItem(
                            "tokens"
                          )}`,
                        },
                      }
                    )
                    .then((result) => {
                      if (result.data.type === "Success") {
                        message.success("Appointment Cancelled");
                        // setLoader(true);
                        document.getElementById(
                          record.appointment_info.appointment_id
                        ).style.display = "none";
                        fetchExternalAppointments();
                      } else {
                        message.warning("Can't Update Request");
                      }
                    })
                    .catch((e) => {
                      message.error("Request Failed");
                      document.getElementById(
                        record.appointment_info.appointment_id
                      ).style.display = "none";
                    });
                }}
                style={{ color: "red" }}
              />

              <Spin
                size="small"
                style={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                  display: "none",
                }}
                id={record.appointment_info.appointment_id}
              />
            </Space>
          );
        } else if (
          record.appointment_info.status === "Scheduled" &&
          record.appointment_info.appt_type === "Online"
        ) {
          const params = {
            purpose: record.appointment_info.purpose,
            patient_id: record.appointment_info.patient_id,
          };
          return (
            <Link
              to={{
                pathname: `/video/${record.appointment_info.appointment_id}#${record.appointment_info.patient_id}`,
                state: params,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VideoCameraTwoTone
                title="Call"
                onClick={() => {
                  localStorage.setItem(
                    "patient_id",
                    record.appointment_info.patient_id
                  );
                  localStorage.setItem(
                    "purpose",
                    record.appointment_info.purpose
                  );
                  localStorage.setItem(
                    "provider_name",
                    record.appointment_provider_info.provider_name
                  );
                  localStorage.setItem(
                    "provider_email",
                    record.appointment_provider_info.provider_type
                  );
                }}
              />
            </Link>
          );
        }
      },
    },
  ];

  useEffect(() => {
    props?.AppointmentId
      ? fetchParticularAppointments(props.AppointmentId)
      : fetchExternalAppointments();
  }, []);

  const fetchExternalAppointments = () => {
    const url = `https://app02.thbglobal.com/v1/appointmentsStatus/appointments?userId=${PatientId}&userType=doctors`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "success") {
          setExternalAppointments(result.data);
          setExternalReportsToggle(true);
        } else {
          setExternalReportsToggle(true);
          message.error(result.description);
        }
      });
  };

  const fetchParticularAppointments = (AppointmentId) => {
    const url = `https://app02.thbglobal.com/v1/appointments/${AppointmentId}`;
    setAppointment(AppointmentId);
    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setRecord(result);
        setProviderNotes(result.appointment_info.provider_notes);
        setLoader(true);
      });
  };

  // const submitFeedback = () => {
  //   setToggle(true);
  //   const url = `https://app02.thbglobal.com/v1/appointments/${appt_id}`;

  //   const data = {
  //     provider_notes: value,
  //     display_provider_notes: showNotes,
  //     status: status.toLowerCase(),
  //   };

  //   fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
  //       Accept: "application/json, text/plain, */*",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.type === "Success") {
  //         setToggle(false);
  //         message.success(result.message);
  //         setValue("");
  //       } else {
  //         setToggle(false);
  //         message.error(result.description);
  //       }
  //     });
  // };

  return (
    <>
      {props?.callFeedback ? (
        <>
          <ExternalAppointmentsDetails
            record={record}
            loader={loader}
            toggle={toggle}
            setShowNotes={setShowNotes}
            showNotes={showNotes}
            status={status}
            setShowTable={setShowTable}
            setLoader={setLoader}
            setRecord={setRecord}
            // submitFeedback={submitFeedback}
            providerNotes={providerNotes}
            isCallFeedback={props.callFeedback}
          />
        </>
      ) : (
        <div className="appointments">
          <Layout className="layout">
            <Header>
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link to="/dashboard">Back to Dashboard</Link>
                </Menu.Item>
              </Menu>
            </Header>
            <Layout hasSider="true">
              <SiderComponent selectedKey="2" />
              <Content style={{ padding: "0 50px" }}>
                <div className="appointments-container">
                  <div className="page-title">
                    <h1>External Appointments</h1>
                  </div>
                  {/* <AppointmentsForm id={pid} /> */}
                </div>
                <div className="site-layout-content">
                  {!showTable ? (
                    <ArrowLeftOutlined
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setShowTable(true);
                        setLoader(false);
                        setRecord([]);
                      }}
                    />
                  ) : (
                    <span style={{ margin: "10px" }}>
                      {" "}
                      Appointments Details
                    </span>
                  )}

                  <Divider />
                  {externalReportsLoader ? (
                    showTable && (
                      <Table
                        dataSource={externalAppointments}
                        columns={columns}
                        pagination={{ position: ["bottomCenter"] }}
                        rowKey={(record) =>
                          record.appointment_info.appointment_id
                        }
                      />
                    )
                  ) : (
                    <Spin
                      size="large"
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  )}

                  {!showTable ? (
                    <ExternalAppointmentsDetails
                      record={record}
                      loader={loader}
                      toggle={toggle}
                      setShowNotes={setShowNotes}
                      showNotes={showNotes}
                      status={status}
                      value={value}
                      // submitFeedback={submitFeedback}
                      setShowTable={setShowTable}
                      setLoader={setLoader}
                      setRecord={setRecord}
                      providerNotes={providerNotes}
                      isCallFeedback={true}
                    />
                  ) : null}
                </div>
              </Content>
              <Footer style={{ textAlign: "right" }}></Footer>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};
export default ExternalAppointments;
