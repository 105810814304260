import React, {useState,  Fragment } from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import {Space, Table, Modal, Steps, Layout} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import InputForm from "../../medicalreports/form.component";
import { DataPreview } from "../../../Pages/external-appointments/callFeedback.component";
import moment from "moment";


const { Step } = Steps;
const { Footer } = Layout;

const steps = [
  {
    title: 'Consultation Information',
    content: 'First-content',
  },
  {
    title: 'Prescriptions',
    content: 'Second-content',
  },
  {
    title: 'Reports',
    content: 'Last-content',
  },
];

const ReportsComponent = (props) => {

  const [medicalreports, setMedicalReports] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState([]);

  
  const AppointmentId = props?.match?.params?.id
  ? props.match.params.id
  : props.appt_id;

  const PatientId = props?.patient_id;
  
  const reportsColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },

    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: "Document Date",
      dataIndex: "generation_date",
      key: "generation_date",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              setRecord(record);
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];
  

  
  const handleMedicalReports = (data) => {
    setMedicalReports([data]);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };


 const next = e => {
    e.preventDefault();
    //PROCESS FORM//
    props.nextStep();
  };

  const back = e => {
    e.preventDefault();
    props.prevStep();
  };
  // background: "#098F8F",

    return (
      <Fragment>
        <AppBar style={{background: "#333", marginBottom:"10px" }} position="sticky">
          <Toolbar title="Add Document Details">
            <Typography color="inherit" variant="title">
              Medical Reports ( Anything saved in this section will be saved directly to the database )
            </Typography>
          </Toolbar>
        </AppBar>

        <InputForm
              onMedicalSubmit={handleMedicalReports}
              appt_id={AppointmentId}
              patient_id={PatientId}
              document={"Upload Document"}
            />
            
            {medicalreports && Object.keys(medicalreports).length !== 0 && (
              <><h4 style={{ marginLeft: "10px", marginTop: "10px" }}>Uploaded Files</h4>
              <Table
                dataSource={medicalreports}
                columns={reportsColumns}
                pagination={{ position: ["bottomCenter"] }}
              />
              </>
            )}

        <Modal
            title="Details"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
          >
            <DataPreview data={record} type='reports' />
          </Modal>

        <br />

        <br />
        <Button
          style={{
            background: "#EE3B55",
            color: "#FFFFFF",
            marginRight: "1em"
          }}
          label="Continue"
          variant="outlined"
          size="large"
          onClick={back}
        >
          Back
        </Button>
        <Button
          style={{
            background: "#3C61B8",
            color: "#FFFFFF",
            marginRight: "1em"
          }}
          label="Continue"
          variant="outlined"
          size="large"
          onClick={next}
        >
          To First
        </Button>

        <Footer >
        <Steps current={props.step}  size="small" >
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      </Footer>
      </Fragment>
    );
}

export default ReportsComponent;
