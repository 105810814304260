import { Modal, Button, InputNumber } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import React from "react";
import axios from "axios";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

class UpdateProfile extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
  };

  upload(e) {
    console.log(e.target.files[0]);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/doctors/${this.props.id}`;

    const data = {
      doctor_id: parseInt(this.props.id),
      facility_id: 0,
      name: values.name,
      dob: values["dob"].format("YYYY-MM-DD"),
      gender: values.gender,
      email: values.email,
      phone: values.phone,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
      country: values.country,
      profession: values.profession,
      specialization: values.specialization,
      experience: String(values.experience), //here
      fees: values.fees,
      photo: "",
      qualification_one: values.qualification_one,
      qualification_two: values.qualification_two,
      license_number: values.license_number,
      verified: false,
      verification_token: "",
      verification_token_expiry: "",
      status: "",
      created_at: new Date("2021-11-11 00:00:00"),
      updated_at: new Date("2021-11-11 00:00:00"),
      approved: "false",
      ratings: "0.0",
      ratings_count: "0",
    };
    this.handleOk();

    axios
      .put(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.statusText === "OK") {
          this.setState({ loading: false, visible: false });
          message.success("Profile Details Updated");
          this.props.fetchDetails();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Profile Data Can't Be Updated");
        console.log(err);
      });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <EditOutlined
          title="Update"
          style={{ color: "blue", cursor: "pointer", fontSize: "150%" }}
          onClick={this.showModal}
        />

        <Modal
          visible={visible}
          title="Update Record"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
            initialValues={{
              name: this.props.name,
              email: this.props.email,
              gender: this.props.gender,
              dob: moment(this.props.dob),
              address_line_1: this.props.address_line_1,
              address_line_2: this.props.address_line_2,
              city: this.props.city,
              state: this.props.state,
              country: this.props.country,
              license_number: this.props.license_number,
              specialization: this.props.specialization,
              qualification_one: this.props.qualification_one,
              qualification_two: this.props.qualification_two,
              profession: this.props.profession,
              phone: this.props.phone,
              pincode: this.props.pincode,
              experience: 0,
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="dob"
              label="DOB"
              // initialValue={this.props.patient_dob}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please Select Your DOB!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address_line_1"
              label="Address Line 1"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address_line_2"
              label="Address Line 2"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="pincode"
              label="Pincode"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="profession" label="Profession">
              <Input />
            </Form.Item>

            <Form.Item
              name="qualification_one"
              label="Primary Qualification"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="qualification_two"
              label="Secondary Qualification"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="specialization"
              label="Specialization"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="experience"
              label="Experience"
              rules={[
                {
                  type: "number",
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              name="fees"
              label="Fees"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="license_number"
              label="License Number"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateProfile;
