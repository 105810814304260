import React from "react";
import { Descriptions, Spin, Input, Divider, Checkbox } from "antd";
import CallFeedback from "./callFeedback.component";
import UserForm from "./UserForm";

const { TextArea } = Input;

const ExternalAppointmentsDetails = ({
  record,
  loader,
  setShowNotes,
  showNotes,
  status,
  providerNotes,
  isCallFeedback,
  setShowTable,
  setLoader,
  setRecord,
}) => {
  return (
    <div>
      {loader ? (
        <>
          <Descriptions
            bordered
            title="Appointment Details"
            size="small"
            column={1}
            labelStyle={{ backgroundColor: "#dad2d2" }}
          >
            <Descriptions.Item label="Appointment Id">
              {record?.appointment_info?.appointment_id}
            </Descriptions.Item>
            <Descriptions.Item label="Appointments Type">
              {record.appointment_info.appt_type}
            </Descriptions.Item>
            <Descriptions.Item label="Complaints">
              {record.appointment_info.complaints}
            </Descriptions.Item>
            <Descriptions.Item label="Remarks">
              {record.appointment_info.provider_notes}
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              {record.appointment_info.remarks}
            </Descriptions.Item>

            <Descriptions.Item label="Purpose">
              {record.appointment_info.purpose}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {record.appointment_info.status}
            </Descriptions.Item>
            <Descriptions.Item label="Provider Id">
              {record.appointment_provider_info.provider_id}
            </Descriptions.Item>
            <Descriptions.Item label="Patient Name">
              {record.appointment_provider_info.provider_name}
            </Descriptions.Item>
            <Descriptions.Item label="Patient Email">
              {record.appointment_provider_info.provider_type}
            </Descriptions.Item>
            <Descriptions.Item label="End Time">
              {record.appointment_slot_info.end_time}
            </Descriptions.Item>
            <Descriptions.Item label="Slot Date">
              {record.appointment_slot_info.slot_date}
            </Descriptions.Item>
            <Descriptions.Item label="Start Time">
              {record.appointment_slot_info.start_time}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          {/* <h2>Consultation Notes</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <h4>Provider Notes</h4>
            <div style={{ marginRight: "50%" }}>
              <Checkbox
                style={{ marginRight: "15px" }}
                onChange={() => setShowNotes(true)}
              />
              <span>Display Notes</span>
            </div>
          </div>

          <TextArea
            defaultValue={providerNotes}
            autoSize={true}
            onChange={handleChange}
            style={{ marginBottom: "15px", width: "50%" }}
            // value={value}
          /> */}

          {/* <Button
            type="primary"
            htmlType="submit"
            disabled={value ? false : true}
            style={{ marginBottom: "15px", marginTop: "15px" }}
            onClick={submitFeedback}
          >
            {toggle && <LoadingOutlined />}
            Submit
          </Button> */}

          {isCallFeedback && (
            <UserForm
              appt_id={record?.appointment_info?.appointment_id}
              external={true}
              providerNotes={providerNotes}
              setShowNotes={setShowNotes}
              showNotes={showNotes}
              status={status}
              remarks={record?.appointment_info?.remarks}
              patient_id={record?.appointment_info?.patient_id}
              setShowTable={setShowTable}
              setLoader={setLoader}
              setRecord={setRecord}
            />

            // <CallFeedback
            //   appt_id={record?.appointment_info?.appointment_id}
            //   external={true}
            //   showNotes={showNotes}
            //   status={status}
            //   value={value}
            //   remarks={record?.appointment_info?.remarks}
            //   patient_id={record?.appointment_info?.patient_id}
            //   setShowTable={setShowTable}
            //   setLoader={setLoader}
            //   setRecord={setRecord}
            // />
          )}
        </>
      ) : (
        <Spin
          size="large"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      )}
    </div>
  );
};

export default ExternalAppointmentsDetails;
