import React, { useState, Fragment } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

import axios from "axios";
import {
  Input,
  Checkbox,
  Form,
  Button,
  Select,
  message,
  Spin,
  Steps,
  Layout,
} from "antd";

const { Step } = Steps;
const { Footer } = Layout;

const steps = [
  {
    title: "Consultation Information",
    content: "First-content",
  },
  {
    title: "Prescriptions",
    content: "Second-content",
  },
  {
    title: "Reports",
    content: "Last-content",
  },
];

const { Option } = Select;

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

const ConsultationNotesComponent = (props) => {
  const [spin, setSpin] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [status, setStatus] = useState("");

  const { providerNotes, Remarks } = props;

  const [form] = Form.useForm();

  const AppointmentId = props?.match?.params?.id
    ? props.match.params.id
    : props.appt_id;

  const PatientId = props?.patient_id;

  const next = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handleStatus = (val) => {
    setStatus(val);
  };

  const onFinish = (values) => {
    setSpin(true);
    let data = {};
    if (values.Notes && values.status && values.Remarks) {
      data = {
        provider_notes: values.Notes,
        display_provider_notes: showNotes,
        status: values.status,
        remarks: values.Remarks,
      };
    } else if (values.Notes && values.status) {
      data = {
        provider_notes: values.Notes,
        display_provider_notes: showNotes,
        status: values.status,
      };
    } else {
      data = {
        remarks: values.Remarks,
        status: values.status,
      };
    }

    axios
      .put(`https://app02.thbglobal.com/v1/appointments/${AppointmentId}`, data)
      .then((result) => {
        if (result.data.type === "Success") {
          form.resetFields();
          setSpin(false);
          message.success(
            "Your remarks and/or medications & file data has been stored successfully"
          );

          // props.setShowTable(true);
          // props.setLoader(false);
          // props.setRecord([]);
        }
      })
      .catch((e) => {
        message.error(e);
      });
  };

  return (
    <Fragment>
      <AppBar style={{ background: "#2E3B55" }} position="sticky">
        <Toolbar title="Enter User Details">
          <Typography color="inherit" variant="title">
            Consultation Notes
          </Typography>
        </Toolbar>
      </AppBar>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <span>Provider Notes</span>
        <div style={{ marginRight: "50%" }}>
          <Checkbox
            style={{ marginRight: "15px" }}
            onChange={() => setShowNotes(true)}
          />
          <span>Display Notes</span>
        </div>
      </div>
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        initialValues={{
          Remarks: Remarks,
          Notes: providerNotes,
        }}
        onFinish={onFinish}
      >
        <Form.Item name="Notes">
          <Input.TextArea
            autoSize={true}
            style={{ marginBottom: "15px", width: "100%" }}
            // value={value}
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <span>Notes To Patient</span>
        </div>
        <Form.Item name="Remarks">
          <Input.TextArea />
        </Form.Item>

        <span>Please Select Status</span>
        <Form.Item
          name="status"
          rules={[
            {
              required: true,
              message: "Please select status to move ahead",
            },
          ]}
        >
          <Select allowClear style={{ width: 120 }} onChange={handleStatus}>
            <Option key="cancelled" value="cancelled">
              Cancelled
            </Option>
            <Option key="attended" value="attended">
              Attended
            </Option>
            <Option key="missed" value="missed">
              Missed
            </Option>
            <Option key="not_attended" value="not_attended">
              Not Attended
            </Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              background: "#991A76",
              color: "#FFFFFF",
              marginRight: "5px",
            }}
            htmlType="submit"
            variant="outlined"
            size="medium"
          >
            {spin ? <Spin size="small" /> : `Save`}
          </Button>
          <Button
            style={{ background: "#2E3B55", color: "#FFFFFF" }}
            label="Skip"
            variant="outlined"
            size="medium"
            onClick={next}
            disabled={status ? false : true}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>

      <Footer>
        <Steps current={props.step} size="small">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </Footer>
    </Fragment>
  );
};

export default ConsultationNotesComponent;
