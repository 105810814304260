import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./login.styles.css";
import axios from "axios";
import { Form, Input, Button, Checkbox, Layout, Select, Image } from "antd";
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../context/auth";
import { getFCMToken } from "../../firebaseInit";
import logo from "../../Thb_Logo.jpeg";

const { Header, Content } = Layout;
const { Option } = Select;

const Error = styled.div`
  background-color: red;
`;

const Login = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setUserName] = useState("");
  const [usertype, setUserType] = useState("");
  const [password, setPassword] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const { setAuthTokens } = useAuth();
  const _isMounted = useRef(true); // Initial value _isMounted = true

  // const onFinish = (values) => {
  //   console.log("Received values of form: ", values);
  // };

  const setValue = (value) => {
    if (value === "Health Manager") {
      setUserType("healthManager");
    } else if (value === "Doctor") {
      setUserType("doctors");
    } else {
      setUserType("admin");
    }
  };

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const menu = ["Health Manager", "Doctor", "Admin"];

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getFCMToken(setTokenFound);
      if (data) {
        setFcmToken(data);
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  function getTimeZone() {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2)
    );
  }

  function postLogin() {
    // localStorage.clear();
    setLoading(true);
    axios
      .post("https://app02.thbglobal.com/v1/sessions", {
        username: username,
        password: password,
        usertype: usertype,
        device_timezone: getTimeZone().toString(),
        ip_address: "192.168.43.161",
        device_type: "Web",
        device_id: fcmToken,
      })
      .then((result) => {
        if (_isMounted.current && result.data.message === "Success") {
          localStorage.setItem("username", result.data.data[0].user_name);
          localStorage.setItem("useremail", result.data.data[0].user_email);
          localStorage.setItem("userid", result.data.data[0].user_id);
          localStorage.setItem("usertype", result.data.data[0].user_type);
          setAuthTokens(result.data.data[0].token);
          setLoggedIn(true);
          setLoading(false);
        } else {
          setIsError(true);
          setLoading(false);
        }
      })
      .catch((e) => {
        setIsError(true);
        setLoading(false);
      });
  }

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Layout>
      <Header className="Header" style={{ padding: 0 }}>
        <div
          className="site-layout-background"
          style={{
            height: 0,
            fontSize: "medium",
            paddingLeft: "10px",
            marginTop: "10px",
          }}
        >
          {/* <h1 style={{ color: "#fff" }}>THB WEB</h1> */}

          <Image
            src={logo}
            preview={false}
            style={{ height: "50px", width: "150px" }}
          />
        </div>
      </Header>
      <div className="login-container">
        <Content>
          <div className="row justify-content-center">
            <div className="card">
              <div className="card-header">Login</div>
              <div className="card-body">
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  // onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      suffix={
                        !passwordShown ? (
                          <EyeOutlined onClick={togglePassword} />
                        ) : (
                          <EyeInvisibleOutlined onClick={togglePassword} />
                        )
                      }
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={setValue}
                      placeholder=" User Type"
                    >
                      {/* <Option value="1">Option 1</Option> */}
                      {menu.map((val) => {
                        return (
                          <Option key={val} value={val}>
                            {val}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                    <Link to="/reset" className="login-form-forgot">
                      Forgot password
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={postLogin}
                    >
                      {loading && <LoadingOutlined />}
                      Sign in
                    </Button>
                  </Form.Item>
                </Form>
                {isError && (
                  <Error>
                    The username or password provided were incorrect!
                  </Error>
                )}
              </div>
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Login;
