import React, {  useState } from "react";
import ConsultationNotesComponent from "../../Components/stepperForm/components/ConsultationNotesComponent";
import PrescriptionComponent from "../../Components/stepperForm/components/PrescriptionComponent";
import ReportsComponent from "../../Components/stepperForm/components/ReportsComponent";
import Success from "../../Components/stepperForm/components/Success";


const UserForm = (props) =>{
    
    const [step , setStep] = useState(0);

  // Go to next step
 const nextStep = () => {
    setStep(step + 1);
  };

  // Go to prev step
  const prevStep = () => {
    setStep(step - 1);
  };
  const  firstStep = () => {
    setStep(0);
  };
  

    const {providerNotes, remarks} = props;

    // const values = { firstName, lastName, email, occupation, city, bio };

    switch (step) {
      default:
        return <h1>User Forms not working.</h1>;
      case 0:
        return (
          <ConsultationNotesComponent
            nextStep={nextStep}
            providerNotes={providerNotes}
            step = {step}
            Remarks={remarks}
            {...props}
          />
        );
      case 1:
        return (
          <PrescriptionComponent
            nextStep={nextStep}
            prevStep={prevStep}
            step = {step}
            {...props}
          />
        );
      case 2:
        return (
          <ReportsComponent
            nextStep={firstStep}
            prevStep={prevStep}
            step = {step}
            {...props}
          />
        );
      //
      case 3:
        return <Success firstStep={firstStep} />;
    }
  
}
export default UserForm;
