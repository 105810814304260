import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Descriptions, Spin } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space, Modal } from "antd";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import "./internalUserManagement.styles.css";
import InternalUserDoctorForm from "../../Components/internaluser/internalUserDoctorForm.component";
import InternalUserHealthManagerForm from "../../Components/internaluser/internalUserHealthManagerForm.component";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content } = Layout;
const { Option } = Select;

const InternalUserManagement = (props) => {
  const [usersLoader, setUsersToggle] = useState(false);
  const [userLoader, setUserToggle] = useState(true);
  const [users, setUsers] = useState([]);
  const [data, setUser] = useState([]);
  const [category, setCategory] = useState("doctors");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const doctorColumns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Profession",
      dataIndex: "profession",
      key: "profession",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              getInternalUserManagementById(record.doctor_id);
              setUserToggle(() => false);
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const hmColumns = [
    {
      title: "Name",
      dataIndex: "hm_name",
      key: "hm_name",
    },
    {
      title: "Email",
      dataIndex: "hm_email",
      key: "hm_email",
    },
    {
      title: "DOB",
      dataIndex: "hm_dob",
      key: "hm_dob",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              getInternalUserManagementById(record.hm_id);
              setUserToggle(() => false);
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const fetchInternalUserManagement = () => {
    const url = `https://app02.thbglobal.com/v1/internalUserManagement?category=${category}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setUsers(result.data);

          setUsersToggle(() => true);
        } else {
          setUsersToggle(true);
        }
      });
  };

  const getInternalUserManagementById = (userId) => {
    const url = `https://app02.thbglobal.com/v1/internalUserManagement/${category}/${userId}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setUser(result.data);
          setUserToggle(() => true);
        } else {
          setUserToggle(true);
        }
      });
  };

  const handleChange = (value) => {
    setCategory(value);
    // fetchInternalUserManagement();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchInternalUserManagement();
    // setId(PatientId);
  }, [category]);

  return (
    <div className="internal-user">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="4" defaultOpenKeys="sub1" />
          <Content style={{ padding: "0 50px" }}>
            <div className="user-container">
              <div className="page-title">
                <h1> Internal User Management </h1>
              </div>

              <Select defaultValue="doctors" onChange={handleChange}>
                <Option value="healthManagers">Health Managers</Option>
                <Option value="doctors">Doctors</Option>
              </Select>
            </div>
            <div className="site-layout-content">
              <div className="sub-heading">
                {category === "doctors" ? (
                  <span>Doctors</span>
                ) : (
                  <span>Helth Manager</span>
                )}
                <InternalUserDoctorForm
                  fetchInternalUserManagement={fetchInternalUserManagement}
                />
                <InternalUserHealthManagerForm
                  fetchInternalUserManagement={fetchInternalUserManagement}
                />
              </div>
              <Divider />
              {usersLoader ? (
                <Table
                  dataSource={users}
                  columns={category === "doctors" ? doctorColumns : hmColumns}
                  rowKey="medication_id"
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}

              <Modal
                // title="Doctor Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {userLoader ? (
                  <DataPreview data={data} user={category} />
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Modal>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "right" }}> */}
          {/* <MedicationForm id={pid} /> */}
          {/* </Footer> */}
        </Layout>
      </Layout>
    </div>
  );
};
export default InternalUserManagement;

const DataPreview = ({ data, user }) => {
  return (
    <>
      {user === "doctors" ? (
        <Descriptions
          bordered
          title="Doctor Details"
          size="large"
          column={1}
          labelStyle={{ backgroundColor: "#dad2d2" }}
        >
          <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
          <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
          <Descriptions.Item label="Specialization">
            {data.specialization}
          </Descriptions.Item>
          <Descriptions.Item label="Profession">
            {data.profession}
          </Descriptions.Item>
          <Descriptions.Item label="Experience">
            {data.experience}
          </Descriptions.Item>
          <Descriptions.Item label="DOB">{data.dob}</Descriptions.Item>
          <Descriptions.Item label="Gender">{data.gender}</Descriptions.Item>
          <Descriptions.Item label="Phone">{data.phone}</Descriptions.Item>
          <Descriptions.Item label="City">{data.city}</Descriptions.Item>
          <Descriptions.Item label="State">{data.state}</Descriptions.Item>
        </Descriptions>
      ) : (
        <Descriptions
          bordered
          title="Health Manager Details"
          size="large"
          column={1}
          labelStyle={{ backgroundColor: "#dad2d2" }}
        >
          <Descriptions.Item label="Name">{data.hm_name}</Descriptions.Item>
          <Descriptions.Item label="Email">{data.hm_email}</Descriptions.Item>
          <Descriptions.Item label="DOB">{data.hm_dob}</Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
