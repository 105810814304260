import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import React from "react";
import axios from "axios";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const children = [];
const Services = {
  1: "Global Care",
  3: "Home Nursing",
  4: "Connected Care",
  5: "24/7 Doctor",
  6: "Health Manager",
  7: "EHR",
};

for (const item in Services) {
  children.push(<Option key={item}>{Services[item]}</Option>);
}

class UpdateSubscription extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/signupCode/${this.props.sc_id}`;

    const data = {
      organization: values.organization,
      code: values.code,
      start_date: values["start_date_picker"].format("YYYY-MM-DD"),
      end_date: values["end_date_picker"].format("YYYY-MM-DD"),
      head_count: 0,
      service_id: values.service_id.map(Number),
      status: "Active",
    };
    this.handleOk();

    axios
      .put(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          this.setState({ loading: false, visible: false });
          message.success("Subscription Details Updated");
          this.props.fetchSignUpData();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Subscription Data Can't Be Updated");
        console.log(err);
      });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <div className="input-form">
          <EditOutlined
            title="Update"
            style={{ color: "blue", cursor: "pointer" }}
            onClick={this.showModal}
          />
        </div>
        <Modal
          visible={visible}
          title="Update Record"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
            initialValues={{
              organization: this.props.organization,
              code: this.props.code,
              start_date_picker: moment(this.props.start_date, "DD/MM/YYYY"),
              end_date: moment(this.props.end_date, "DD/MM/YYYY"),
              service_id: Services[this.props.service_id],
            }}
          >
            <Form.Item
              name="organization"
              label="Organization"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="start_date_picker"
              label="Start Date"
              // initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please Select Start Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="end_date_picker"
              label="End Date"
              // initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please Select End Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="service_id"
              label="Select Sevices"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
              >
                {children}
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateSubscription;
