import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

class HealthBoardForm extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/healthBoard`;
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("title", values.user.title);
    formData.append("article_date", values["date-picker"].format("YYYY-MM-DD"));
    formData.append("description", values.user.description);
    formData.append("written_by", values.user.written_by);
    formData.append("url", values.user.url);
    formData.append("other_data_one", values.user.other_data_one);
    formData.append("picture", this.state.selectedFile);

    axios
      .post(url, formData, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        message.success("Data Uploaded");
        this.setState({ loading: false, visible: false });
        this.props.fetchHealthBoardData();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Upload Error");
      });
  };

  handleChange = (e) => {
    let files = e.target.files;

    var filesize = (files[0]?.size / 1024 / 1024).toFixed(5); // MB
    // this.upload(e);
    if (filesize > 5) {
      alert("File Size too big");
    } else {
      this.setState({
        selectedFile: files[0],
      });
    }
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Upload Details
        </Button>

        <Modal
          visible={visible}
          title="Add New Record"
          onOk={this.handleOk}
          width={1000}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
          >
            <Form.Item
              name={["user", "title"]}
              label="Title"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="date-picker"
              label="Article Date"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name={["user", "description"]}
              label="Description"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name={["user", "written_by"]}
              label="Written By"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["user", "url"]}
              label="URL"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["user", "other_data_one"]}
              label="Category"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Picture">
              <input
                type="file"
                value={this.selectedFile}
                onChange={this.handleChange}
                name="file"
                accept="image/jpg, image/jpeg, image/png"
              />
              <span style={{ fontSize: "10px" }}>
                <strong>Accepted File Format :</strong> .jpg/.jpeg/.png |
              </span>
              <span style={{ fontSize: "10px" }}>
                {" "}
                <strong> File-Size :</strong> 5MB
              </span>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default HealthBoardForm;
