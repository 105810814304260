import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

class InternalUserHealthManagerForm extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/internalUserManagement/healthManager`;

    let data = {
      hm_name: values.user["name"],
      hm_email: values.user["email"],
      hm_dob: values["dob"].format("YYYY-MM-DD"),
      hm_start_date: moment(values["start-date"].format("YYYY-MM-DD HH:MM:SS")),
    };
    this.handleOk();

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          // this.props.onMedicalSubmit(res.data.data  );
          message.success("Health Manager Created");
          this.props?.id
            ? this.props.fetchInternalUserManagement()
            : console.log("");
          this.setState({ loading: false, visible: false });
        } else {
          this.setState({ loading: false });
          message.warning("Can't Create Health Manager");
        }
      })
      .catch((err) => {
        message.error("Error Creating Health Manager");
      });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Add Health Manager
        </Button>

        <Modal
          visible={visible}
          title="Add New Record"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
          ]}
        >
          <Form
            {...layout}
            name="nest-messages"
            onFinish={(values) => this.onFinish(values)}
            validateMessages={validateMessages}
          >
            <Form.Item
              name={["user", "name"]}
              label="Name"
              rules={[
                {
                  required: true,
                  type: "string",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["user", "email"]}
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="dob"
              label="DOB"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select time!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="start-date"
              label="Start Date"
              initialValue={moment()}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Please select time!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {loading && <LoadingOutlined />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default InternalUserHealthManagerForm;
