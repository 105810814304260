import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, InputNumber, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 14,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const userType = localStorage.getItem("usertype");

class MedicationForm extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectValues: { drugValues: [], frequency: [], strength: [] },
    drug: "",
    frequency: "",
    strength: "",
    platform: "On Platform",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.dropdown();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleChange = (value) => {
    this.setState({ platform: value });
  };

  dropdown = () => {
    let url = "https://app02.thbglobal.com/v1/dynamicData/dropdowns";
    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.type === "Success") {
          this.setState({
            selectValues: {
              ...this.state.selectValues,
              drugValues: res.data.medication_drug_form,
              frequency: res.data.medication_frequency,
              strength: res.data.medication_strength,
            },
          });
        }
      })
      .catch((err) => message.error(err));
  };

  componentDidMount = () => {
    if (this.props?.appt_id) this.dropdown();
  };

  onFinish = (values) => {
    const PatientId = this.props?.id ? this.props?.id : this.props?.patient_id;

    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}/prescriptions`;

    let data = {
      appointment_id: parseInt(this.props.appt_id)
        ? parseInt(this.props.appt_id)
        : 0,
      dosage: values.user["dosage"],
      drug_form: this.state.drug,
      drug_name: values.user["drugName"],
      drug_strength:
        String(values.user["drugStrength"]) + values.user["drugUnit"],
      end_date: values["date-pickerend"].format("YYYY-MM-DD"),
      frequency: this.state.frequency,
      instructions: values.user["instructions"],
      intake_method: values.user["intake_method"],
      // patient_id: this.props.id,
      prescribed_by:
        userType === "doctors"
          ? this.props?.fromMedications
            ? values?.user["prescribed_by"]
            : localStorage.getItem("username")
          : values?.user["prescribed_by"],
      prescribed_on: this.props?.fromMedications
        ? this.state.platform
        : "On Platform",
      purpose: values.user["purpose"],
      start_date: values["date-picker"].format("YYYY-MM-DD"),
    };
    this.setState({ loading: true });

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        if (res.data.type === "Success") {
          this.props?.appt_id
            ? this.props?.onMedicationSubmit(res.data.data)
            : console.log("");
          this.setState({ loading: false, visible: false });
          message.success("Medication Uploaded");
          this.props?.id ? this.props.fetchMedications() : console.log("");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Medication Upload Error");
      });
  };

  setDrug = (val) => {
    this.setState({ drug: val });
  };

  setStrength = (val) => {
    this.setState({ strength: val });
  };

  setFrequency = (val) => {
    this.setState({ frequency: val });
  };

  render() {
    const { visible, loading } = this.state;

    return (
      <>
        {this.props?.appt_id ? (
          <FormComponent
            loading={loading}
            onFinish={this.onFinish}
            setDrug={this.setDrug}
            selectValues={this.state.selectValues}
            setStrength={this.setStrength}
            setFrequency={this.setFrequency}
            userType={userType}
            fromMedications={this.props?.fromMedications}
            handleChange={this.handleChange}
            appt_id={this.props?.appt_id}
          />
        ) : (
          <>
            <Button type="primary" onClick={this.showModal}>
              {this.props?.presciption
                ? this.props?.presciption
                : "Add Medications"}
            </Button>
            <Modal
              visible={visible}
              title="Add New Record"
              onOk={this.handleOk}
              width={800}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Return
                </Button>,
              ]}
            >
              <FormComponent
                loading={loading}
                onFinish={this.onFinish}
                setDrug={this.setDrug}
                selectValues={this.state.selectValues}
                setStrength={this.setStrength}
                setFrequency={this.setFrequency}
                userType={userType}
                fromMedications={this.props?.fromMedications}
                handleChange={this.handleChange}
              />
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default MedicationForm;

// can set in any element like this, style={{ width: 120 }}
// throw "stop execution";

export const FormComponent = (props) => {
  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={(values) => props.onFinish(values)}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["user", "drugName"]}
        label="Medicine Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["user", "drugform"]}
        label="Form of Medicine"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select allowClear onChange={props.setDrug}>
          {props.selectValues?.drugValues.map((val) => {
            return (
              <Option key={val} value={val}>
                {val}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Strength" style={{ marginBottom: 0 }}>
        <Form.Item
          name={["user", "drugStrength"]}
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["user", "drugUnit"]}
          rules={[{ required: true, message: "Unit is required" }]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <Select allowClear onChange={props.setStrength}>
            {props.selectValues?.strength?.map((val) => {
              return (
                <Option key={val} value={val}>
                  {val}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        name={["user", "intake_method"]}
        label="Method of Intake"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["user", "dosage"]}
        label="Dosage Quantity"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["user", "frequency"]}
        label="Frequency of Intake"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select allowClear onChange={props.setFrequency}>
          {props.selectValues?.frequency.map((val) => {
            return (
              <Option key={val} value={val}>
                {val}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name={["user", "instructions"]}
        label="Other Instructions"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="date-picker"
        label="Start Date"
        initialValue={moment()}
        rules={[
          {
            type: "object",
            required: true,
            message: "Please select start date!",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="date-pickerend"
        label="End Date"
        initialValue={moment()}
        rules={[
          {
            type: "object",
            required: true,
            message: "Please select end date!",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name={["user", "purpose"]}
        label="Prescribed For"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      {props.userType === "healthManager" ||
        (props?.fromMedications && (
          <Form.Item
            name={["user", "prescribed_by"]}
            label="Prescribed By"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}

      {props?.fromMedications ? (
        <Form.Item
          name="Prescribed On"
          label="Prescribed On"
          initialValue="On Platform"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select allowClear onChange={props.handleChange}>
            <Option key="1" value="On Platform">
              On Platform
            </Option>
            <Option key="2" value="Off Platform">
              Off Platform
            </Option>
          </Select>
        </Form.Item>
      ) : null}

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          {props.loading && <LoadingOutlined />}
          {props?.appt_id ? "Save" : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};
