import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Spin, Descriptions } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space } from "antd";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import "../appointments/appointments.styles.css";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content, Footer } = Layout;

const ExternalClinicalVisits = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [medications, setMedications] = useState([]);
  const [reports, setReports] = useState([]);
  const [record, setRecord] = useState({});
  const [medicationRecord, setMedicationRecord] = useState({});
  const [reportsRecord, setReportsRecord] = useState({});
  const [mime, setMime] = useState("");
  const [filecontent, setFileContent] = useState("");
  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState("reports");

  const Id = localStorage.getItem("userid");

  const columns = [
    {
      title: "Patient Name",
      render: (record) => (
        <Link to={`/vitals/${record.appointment_info.patient_id}`}>
          {record.appointment_provider_info.provider_name}
        </Link>
      ),
      key: "provider_name",
    },
    {
      title: "Facility Name",
      render: (record) => record.appointment_info.facility_name,
      key: "appointment_id",
    },
    {
      title: "Purpose",
      render: (record) => record.appointment_info.purpose,
      key: "purpose",
    },

    {
      title: "Date",
      render: (record) => record.appointment_slot_info.slot_date,
      key: "date",
      sorter: (a, b) =>
        moment(a.appointment_slot_info.slot_date).unix() -
        moment(b.appointment_slot_info.slot_date).unix(),
    },
    {
      title: "Appointment Time",
      render: (record) =>
        `${record.appointment_slot_info.start_time} - ${record.appointment_slot_info.end_time}`,
      key: "start_time",
    },

    {
      title: "Status",
      render: (record) => record.appointment_info.status,
      key: "status",
    },

    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setShowTable(false);
              setRecord(record);
              if (record.appointment_info.status === "Attended") {
                fetchAppointmentsMedications(
                  record.appointment_info.patient_id,
                  record.appointment_info.appointment_id
                );
                fetchAppointmentsReports(
                  record.appointment_info.patient_id,
                  record.appointment_info.appointment_id
                );
              }
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const reportsColumns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Doctor",
      dataIndex: "prescribed_by",
      key: "prescribed_by",
    },
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
    },
    {
      title: "Uploaded On",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      key: "action",
      render: (record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <p
            onClick={() => {
              setReportsRecord(record);
              setCategory("reports");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  const medicationColumns = [
    {
      title: "Drug Name",
      render: (record) => `${record.drug_name} - ${record.drug_strength}`,
      key: "drug_name",
    },
    {
      title: "Drug Form",
      dataIndex: "drug_form",
      key: "drug_form",
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <p
            onClick={() => {
              setMedicationRecord(record);
              setCategory("medication");
              showModal();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <EyeOutlined />
          </p>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = () => {
    const url = `https://app02.thbglobal.com/v1/appointmentsStatus/clinicalVisits?userId=${Id}&userType=doctors`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.message === "Success") {
          setAppointments(result.data);
          setReportsToggle(true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  const fetchAppointmentsMedications = (Id, appt_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${Id}/prescriptions?apptId=${appt_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setMedications(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
        }
      });
  };

  const fetchAppointmentsReports = (Id, appt_id) => {
    const url = `https://app02.thbglobal.com/v1/patients/${Id}/medicalReports?apptId=${appt_id}`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          console.log("Yo enterd");
          setReports(result.data);
          setRecordsToggle(true);
        } else {
          setRecordsToggle(true);
        }
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="appointments">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <SiderComponent selectedKey="3" />
          <Content style={{ padding: "0 50px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>My Clinical Visits</h1>
              </div>
            </div>
            <div className="site-layout-content">
              {!showTable && (
                <ArrowLeftOutlined
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setShowTable(true);
                    setRecordsToggle(false);
                    setRecord([]);
                    setMedications([]);
                    setReports([]);
                  }}
                />
              )}
              <Divider />
              {reportsLoader ? (
                showTable && (
                  <Table
                    dataSource={appointments}
                    columns={columns}
                    rowKey={appointments[0]?.appointment_info.appointment_id}
                    pagination={{ position: ["bottomCenter"] }}
                  />
                )
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}

              {!showTable ? (
                <>
                  <Descriptions
                    bordered
                    title="Appointment"
                    size="default"
                    column={1}
                    labelStyle={{ backgroundColor: "#dad2d2" }}
                  >
                    <Descriptions.Item label="Appointment Id">
                      {record.appointment_info.appointment_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Appointments Type">
                      {record.appointment_info.appt_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Facility Name">
                      {record.appointment_info.facility_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Purpose">
                      {record.appointment_info.purpose}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {record.appointment_info.status}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Provider Id">
                      {record.appointment_provider_info.provider_id}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Provider Name">
                      {record.appointment_provider_info.provider_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Provider Type">
                      {record.appointment_provider_info.provider_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start Time">
                      {record.appointment_slot_info.start_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="End Time">
                      {record.appointment_slot_info.end_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="Slot Date">
                      {record.appointment_slot_info.slot_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Complaints">
                      {record.appointment_info.complaints}
                    </Descriptions.Item>
                    <Descriptions.Item label="Consultation Notes">
                      {record.appointment_info.remarks}
                    </Descriptions.Item>
                  </Descriptions>

                  <Divider />
                  {recordsLoader ? (
                    <>
                      <h4 style={{ marginLeft: "10px" }}>Medications</h4>
                      {medications && medications.length > 0 && (
                        <Table
                          dataSource={medications}
                          columns={medicationColumns}
                          rowKey="medication_id"
                          pagination={{ position: ["bottomCenter"] }}
                        />
                      )}
                      <Divider />
                      <h4 style={{ marginLeft: "10px" }}>Medical Reports</h4>
                      {reports && reports.length > 0 && (
                        <Table
                          dataSource={reports}
                          columns={reportsColumns}
                          pagination={{ position: ["bottomCenter"] }}
                        />
                      )}
                    </>
                  ) : (
                    <Spin
                      size="large"
                      style={{ position: "absolute", top: "50%", left: "50%" }}
                    />
                  )}
                </>
              ) : null}

              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {!showTable && (
                  <ExtraDetails
                    record={record}
                    medicationRecord={medicationRecord}
                    reportsRecord={reportsRecord}
                    setVisible={setVisible}
                    setFileContent={setFileContent}
                    setMime={setMime}
                    setTitle={setTitle}
                    mime={mime}
                    filecontent={filecontent}
                    title={title}
                    visible={visible}
                    type={category}
                  />
                )}
              </Modal>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default ExternalClinicalVisits;

export const ExtraDetails = ({
  setVisible,
  setTitle,
  setMime,
  setFileContent,
  mime,
  filecontent,
  title,
  visible,
  medicationRecord,
  reportsRecord,
  type,
}) => {
  return (
    <>
      {type === "medication"
        ? medicationRecord &&
          Object.keys(medicationRecord).length !== 0 && (
            <Descriptions
              bordered
              title="Medication"
              size="large"
              column={1}
              labelStyle={{ backgroundColor: "#dad2d2" }}
            >
              <Descriptions.Item label="Drug Name">
                {medicationRecord.drug_name}
              </Descriptions.Item>
              <Descriptions.Item label="Drug Form">
                {medicationRecord.drug_form}
              </Descriptions.Item>
              <Descriptions.Item label="Drug Strength">
                {medicationRecord.drug_strength}
              </Descriptions.Item>
              <Descriptions.Item label="Dosage">
                {medicationRecord.dosage}
              </Descriptions.Item>
              <Descriptions.Item label="Frequency">
                {medicationRecord.frequency}
              </Descriptions.Item>
              <Descriptions.Item label="Start Date">
                {medicationRecord.start_date}
              </Descriptions.Item>
              <Descriptions.Item label="End Date">
                {medicationRecord.end_date}
              </Descriptions.Item>
              <Descriptions.Item label="Instructions">
                {medicationRecord.instructions}
              </Descriptions.Item>
              <Descriptions.Item label="Intake Method">
                {medicationRecord.intake_method}
              </Descriptions.Item>
              <Descriptions.Item label="Prescribed On">
                {medicationRecord.prescribed_on}
              </Descriptions.Item>
              <Descriptions.Item label="Prescribed By">
                {medicationRecord.prescribed_by}
              </Descriptions.Item>
            </Descriptions>
          )
        : reportsRecord &&
          Object.keys(reportsRecord).length !== 0 && (
            <Descriptions
              bordered
              title="Medical Report"
              size="large"
              column={1}
              // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              labelStyle={{ backgroundColor: "#dad2d2" }}
            >
              <Descriptions.Item label="Record Name">
                {reportsRecord.file_name}
              </Descriptions.Item>
              <Descriptions.Item label="File Name">
                {reportsRecord.record_name}
              </Descriptions.Item>
              <Descriptions.Item label="Category">
                {reportsRecord.category}
              </Descriptions.Item>
              <Descriptions.Item label="Document Date">
                {reportsRecord.generation_date}
              </Descriptions.Item>
              <Descriptions.Item label="Prescribed By">
                {reportsRecord.prescribed_by}
              </Descriptions.Item>
              <Descriptions.Item label="Facility Name">
                {reportsRecord.facility_name}
              </Descriptions.Item>

              <Descriptions.Item label="Uploaded On">
                {reportsRecord.created_at}
              </Descriptions.Item>
              <Descriptions.Item label="Uploaded By">
                {reportsRecord.upoaded_by}
              </Descriptions.Item>
              {/* <Descriptions.Item label="File Content">
                      {
                        <Button
                          type="primary"
                          size="small"
                          onClick={
                            () => {
                              var signatures = {
                                JVBERi0: "application/pdf",
                                "/9j/": "image/jpeg",
                                iVBORw0KGgo: "image/png",
                              };

                              function detectMimeType(b64) {
                                for (var s in signatures) {
                                  if (b64.indexOf(s) === 0) {
                                    return signatures[s];
                                  }
                                }
                              }
                              setFileContent(reportsRecord.file_content);
                              setTitle(reportsRecord.file_name);
                              let mimeType = detectMimeType(
                                reportsRecord.file_content
                              );
                              setMime(mimeType);
                              setVisible(true);
                            }
                    
                          }
                        >
                          View File
                        </Button>
                      }
                    </Descriptions.Item> */}
              <Descriptions.Item
                label="Notes"
                // contentStyle={{ width: "10px" }}
              >
                {reportsRecord.notes}
              </Descriptions.Item>
            </Descriptions>
          )}
      <Modal
        title={title}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <iframe
          style={{ width: "100%", height: "100vh" }}
          src={`data:${mime};base64,${filecontent}`}
        ></iframe>
      </Modal>
    </>
  );
};
