import React, { Component } from "react";
import { Line } from "@ant-design/charts";

export default class LineChart extends Component {
  render() {
    const data = this.props.data;

    const config = {
      data,
      title: {
        visible: true,
        text: "Chart",
      },
      forceFit: true,
      xField: "vital_date",
      yField: "vital_reading",
      point: {
        visible: true,
        size: 5,
        shape: "circle",
      },
      label: {
        visible:true,
        position:"middle",
        style: {
          fill: "black",
          stroke: "#666c70",
          lineWidth: 2,

        },
      },
    };
    return (
      <div>
        <Line {...config} />
      </div>
    );
  }
}
