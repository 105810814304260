import { Modal, Button, DatePicker, TimePicker, Select } from "antd";
import { Form, Input, InputNumber, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { TextArea } = Input;

function onChange(date, dateString) {
  console.log(date, dateString);
}

const VitalsForm = (props) => {
  const [loading, setToggle] = useState(false);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ unit: props?.unit[0] });
  }, [form, props?.unit[0]]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    const url = `https://app02.thbglobal.com/v1/patients/${props.p_id}/vitals/${props.vitalSelected}`;

    let reading;
    if (props.vitalSelected === "bloodpressure") {
      reading = values.user.systolic + "," + values.user.diastolic;
    } else {
      reading = values.user.reading;
    }

    let data = {
      vital_reading: String(reading),
      vital_unit: values.unit,
      vital_notes: values.user.notes,
      vital_date: values["date-picker"].format("YYYY-MM-DD"),
      vital_time: values["time-picker"].format("HH:mm"),
      uploaded_by: localStorage.getItem("username"),
    };
    setToggle(true);

    axios
      .post(url, data, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        setToggle(false);
        setVisible(false);
        message.success("Vital Data Uploaded");
        // this.props.toggleChange();
        window.location.reload();
      })
      .catch((err) => {
        message.error("Record Update Error", err);
        setToggle(false);
        setVisible(false);
      });
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Upload Vitals
      </Button>

      <Modal
        visible={visible}
        title="Add New Record"
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
        ]}
      >
        <Form
          {...layout}
          name="nest-messages"
          form={form}
          onFinish={(values) => onFinish(values)}
          validateMessages={validateMessages}
          initialValues={{ unit: props?.unit[0] }}
        >
          {/* ref={this.myRef} */}
          {/* <Form.Item
              name={["user", "Id"]}
              label="PatientId"
              rules={[
                {
                  required: true,
                  type: "number",
                },
              ]}
            >
              <InputNumber />
            </Form.Item> */}
          <Form.Item label="Vital Name">
            <span>
              <strong>{props.vitalSelected.toUpperCase()}</strong>
            </span>
          </Form.Item>
          <Form.Item
            name="unit"
            label="Unit"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select allowClear>
              {props.unit?.map((val) => {
                return (
                  <Option key={val} value={val}>
                    {val}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {props.vitalSelected === "bloodpressure" ? (
            <Form.Item
              name={["user", "systolic"]}
              label="Systolic Reading"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                parser={(value) => {
                  return value.substring(0, 3);
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={["user", "reading"]}
              label="Vital Reading"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                parser={(value) => {
                  return value.substring(0, 3);
                }}
              />
            </Form.Item>
          )}
          {props.vitalSelected === "bloodpressure" && (
            <Form.Item
              name={["user", "diastolic"]}
              label="Diastolic Reading"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                parser={(value) => {
                  return value.substring(0, 3);
                }}
              />
            </Form.Item>
          )}

          <Form.Item
            name="date-picker"
            label="Date"
            initialValue={moment()}
            rules={[
              {
                type: "object",
                required: true,
                message: "Please select time!",
              },
            ]}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item
            name="time-picker"
            label="Time"
            initialValue={moment()}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker onChange={onChange} format="HH:mm" />
          </Form.Item>

          <Form.Item
            name={["user", "notes"]}
            label="Notes"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              {loading && <LoadingOutlined />}
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VitalsForm;
