import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Modal, Spin, Descriptions } from "antd";
import { Link } from "react-router-dom";
import { Table, Divider, Space } from "antd";
import moment from "moment";

import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import AppointmentsForm from "../../Components/appointments/appointmentsForm.component";
import "./appointments.styles.css";

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Appointments = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [recordsLoader, setRecordsToggle] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [record, setRecord] = useState({});
  const [pid, setId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const PatientId = props.match.params.id;
  const PatientName = props.location?.param1?.name?.replace("undefined", "");
  const PatientEmail = props.location?.param1?.email;
  const PatientMRN = props.location?.param1?.mrn;
  const PatientPhone = props.location?.param1?.phone;
  var params = {
    name: PatientName,
    email: PatientEmail,
    mrn: PatientMRN,
    phone: PatientPhone,
  };

  const columns = [
    {
      title: "Date",
      render: (record) => record.appointment_slot_info.slot_date,
      key: "date",
      sorter: (a, b) =>
        moment(a.appointment_slot_info.slot_date).unix() -
        moment(b.appointment_slot_info.slot_date).unix(),
    },

    {
      title: "Appointment Time",
      render: (record) =>
        `${record.appointment_slot_info.start_time} - ${record.appointment_slot_info.end_time}`,
      key: "start_time",
    },

    {
      title: "Facility Name",
      render: (record) => record.appointment_info.facility_name,
      key: "appointment_id",
    },
    {
      title: "Doctor Name",
      render: (record) => record.appointment_provider_info.provider_name,
      key: "doctor_name",
    },
    {
      title: "Purpose",
      render: (record) => record.appointment_info.purpose,
      key: "purpose",
    },

    // {
    //   title: "Facility Name",
    //   dataIndex: (record) => record.appointment_info.appt_type,
    //   key: "facility_name",
    // },

    {
      title: "Status",
      render: (record) => record.appointment_info.status,
      key: "status",
    },
    {
      key: "action",
      render: (record) => {
        //
        return (
          <Space size="middle">
            {/* <a>Invite {record.name}</a> */}
            <p
              onClick={() => {
                setRecordsToggle(true);
                setRecord(record);
                ShowModal();
              }}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <EyeOutlined />
            </p>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAppointments();
    setId(PatientId);
  }, []);

  const fetchAppointments = () => {
    const url = `https://app02.thbglobal.com/v1/appointmentsStatus/appointments?userId=${PatientId}&userType=patients`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "success") {
          setAppointments(result.data);
          setReportsToggle(true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  const ShowModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(props);
  return (
    <div className="appointments">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            // collapsed={collapsed}
            className="site-layout-background"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["2"]}
              style={{ height: "100%", borderRight: 0 }}
              defaultOpenKeys={["sub1"]}
            >
              <SubMenu title="Patients" key="sub1">
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/patientsdetails/${PatientId}`,
                      param1: params,
                    }}
                  >
                    {" "}
                    Patient Profile
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/appointments/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Appointments
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/clinicalvisits/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Clinical Visits
                  </Link>
                </Menu.Item>
                <SubMenu title="Vitals" key="4" icon={<UserOutlined />}>
                  <Menu.Item key="s2" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitals/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="s3" icon={<UserOutlined />}>
                    <Link
                      to={{
                        pathname: `/vitalsdata/${PatientId}`,
                        param1: params,
                      }}
                    >
                      Vitals Data Representation{" "}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="5" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medications/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medications
                  </Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<UserOutlined />}>
                  <Link
                    to={{
                      pathname: `/medicalreports/${PatientId}`,
                      param1: params,
                    }}
                  >
                    Medical Reports
                  </Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 50px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>Appointments</h1>
              </div>
              <AppointmentsForm
                id={pid}
                fetchAppointments={fetchAppointments}
              />
            </div>
            <div className="site-layout-content">
              {/* <span> Appointments Details</span> */}
              <h2>
                {PatientName && PatientName !== " " ? `${PatientName}` : null}
                {PatientEmail && PatientEmail !== " "
                  ? `| ${PatientEmail}`
                  : null}
                {PatientMRN && PatientMRN !== " " ? `| ${PatientMRN}` : null}
                {PatientPhone && PatientPhone !== " "
                  ? `| ${PatientPhone}`
                  : null}
              </h2>
              <Divider />
              {reportsLoader ? (
                <Table
                  dataSource={appointments}
                  columns={columns}
                  rowKey={appointments[0]?.appointment_info.appointment_id}
                  pagination={{ position: ["bottomCenter"] }}
                />
              ) : (
                <Spin
                  size="large"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              )}
              {/* <h1 style={{ marginLeft: "10px" }}>Select Patient</h1>*/}
              <Modal
                title="Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
              >
                {recordsLoader ? (
                  <Descriptions
                    bordered
                    title="Appointment"
                    size="default"
                    column={1}
                    labelStyle={{ backgroundColor: "#dad2d2" }}
                  >
                    <Descriptions.Item label="Appointment Id">
                      {record.appointment_info.appointment_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Appointments Type">
                      {record.appointment_info.appt_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Facility Name">
                      {record.appointment_info.facility_name}
                    </Descriptions.Item>
                    {/*<Descriptions.Item label="Patient Id">
                      {record.appointment_info.patient_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payment Id">
                      {record.appointment_info.payment_id}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Purpose">
                      {record.appointment_info.purpose}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {record.appointment_info.status}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Provider Id">
                      {record.appointment_provider_info.provider_id}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Provider Name">
                      {record.appointment_provider_info.provider_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Provider Type">
                      {record.appointment_provider_info.provider_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start Time">
                      {record.appointment_slot_info.start_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="End Time">
                      {record.appointment_slot_info.end_time}
                    </Descriptions.Item>
                    <Descriptions.Item label="Slot Date">
                      {record.appointment_slot_info.slot_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Complaints">
                      {record.appointment_info.complaints}
                    </Descriptions.Item>
                  </Descriptions>
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Modal>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}></Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default Appointments;
