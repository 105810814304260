import { Modal, Button } from "antd";
import { Form, Input, Select, DatePicker, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 14,
  },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const userType = localStorage.getItem("usertype");

class InputForm extends React.Component {
  state = {
    loading: false,
    visible: false,
    selectedFile: [],
    selectValues: [],
    category: "",
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.dropdown();
  };

  handleOk = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  dropdown = () => {
    let url = "https://app02.thbglobal.com/v1/dynamicData/dropdowns";
    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.type === "Success") {
          this.setState({ selectValues: res.data.medical_records });
        }
      })
      .catch((err) => alert("Nothing found on hit"));
  };

  componentDidMount = () => {
    if (this.props?.appt_id) {
      this.dropdown();
    }
  };

  onFinish = (values) => {
    const PatientId = this.props?.id ? this.props?.id : this.props?.patient_id;

    const url = `https://app02.thbglobal.com/v1/patients/${PatientId}/medicalReports`;
    const formData = new FormData();
    formData.append("patient_id", PatientId);
    formData.append(
      "appointment_id",
      parseInt(this.props.appt_id) ? parseInt(this.props.appt_id) : 0
    );
    formData.append(
      "generation_date",
      values["date-picker"].format("YYYY-MM-DD")
    );
    formData.append(
      "prescribed_by",
      userType === "doctors"
        ? localStorage.getItem("username")
        : values?.user?.prescribed_by
    );
    formData.append("file_name", values.user.file_name);
    formData.append("facility_name", values.user.facility_name);
    formData.append("notes", values.user.notes);
    formData.append("category", this.state.category);
    formData.append("file_content", this.state.selectedFile);

    const data = {
      patient_id: this.props.id,
      generation_date: values["date-picker"].format("YYYY-MM-DD"),
      prescribed_by: values.user.prescribed_by,
      file_name: values.user.file_name,
      record_name: this.state.selectedFile.name,
      facility_name: values.user.facility_name,
      notes: values.user.notes,
      category: this.state.category,
      file_content: this.state.selectedFile,
    };

    this.handleOk();

    axios
      .post(url, formData, {
        headers: {
          "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
        },
      })
      .then((res) => {
        console.log("object", res);
        if (res.statusText === "OK") {
          this.props?.appt_id
            ? this.props.onMedicalSubmit(data)
            : console.log("");
          message.success("File Upload success");
          this.props?.id ? this.props.fetchReports() : console.log("");
          this.setState({ loading: false, visible: false });
        }
      })
      .catch((err) => {
        console.log("error", err);
        message.error("File Upload Error");
      });
  };

  handleChange = (e) => {
    let files = e.target.files;

    var filesize = (files[0]?.size / 1024 / 1024).toFixed(5); // MB
    // this.upload(e);
    if (filesize > 5) {
      alert("File Size too big");
    } else {
      this.setState({
        selectedFile: files[0],
      });
    }
  };

  setValue = (val) => {
    this.setState({ category: val });
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <>
        {this.props?.appt_id ? (
          <ReportsFormComponent
            loading={loading}
            onFinish={this.onFinish}
            setValue={this.setValue}
            userType={userType}
            selectValues={this.state.selectValues}
            selectedFile={this.selectedFile}
            handleChange={this.handleChange}
          />
        ) : (
          <>
            <Button type="primary" onClick={this.showModal}>
              {this.props?.document
                ? this.props?.document
                : "Add Medical Records"}
            </Button>

            <Modal
              visible={visible}
              title="Add New Record"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width={800}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Return
                </Button>,
              ]}
            >
              <ReportsFormComponent
                loading={loading}
                onFinish={this.onFinish}
                setValue={this.setValue}
                userType={userType}
                selectValues={this.state.selectValues}
                selectedFile={this.selectedFile}
                handleChange={this.handleChange}
              />
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default InputForm;

export const ReportsFormComponent = (props) => {
  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={(values) => props.onFinish(values)}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={["user", "file_name"]}
        label="File Name"
        rules={[
          {
            type: "string",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        // name={["user", "category"]}
        label="Category"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select allowClear onChange={props.setValue} required="true">
          {/* <Option value="1">Option 1</Option> */}
          {props.selectValues.map((val) => {
            return (
              <Option key={val} value={val}>
                {val}
              </Option>
            );
          })}

          {/* <Cascader options={props.values} /> */}
        </Select>
      </Form.Item>

      <Form.Item
        name="date-picker"
        label="Document Date"
        initialValue={moment()}
        rules={[
          {
            type: "object",
            required: true,
            message: "Please select time!",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      {userType === "healthManager" && (
        <Form.Item
          name={["user", "prescribed_by"]}
          label="Prescribed By"
          rules={[
            {
              type: "string",
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name={["user", "facility_name"]}
        label="Facility Name"
        rules={[
          {
            type: "string",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["user", "notes"]}
        label="Notes"
        rules={[
          {
            type: "string",
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="File Upload">
        <input
          type="file"
          value={props.selectedFile}
          onChange={props.handleChange}
          name="file"
          accept="image/jpg, image/jpeg, image/png, application/pdf"
          required="True"
        />
        <span style={{ fontSize: "10px" }}>
          <strong>Accepted File Format :</strong> .jpg/.jpeg/.png/.pdf |
        </span>
        <span style={{ fontSize: "10px" }}>
          {" "}
          <strong> File-Size :</strong> 5MB
        </span>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          {props.loading && <LoadingOutlined />}
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
