import React from "react";
import LineChart from 'react-linechart';
import '../../../../node_modules/react-linechart/dist/styles.css';


export default class Barchart extends React.Component {
  render() {
    const value = this.props.data;

    const get_readings = () => { 
      let sys_arr = [];
      let dys_arr = [];
      let readings_arr = [];
      value.forEach((val) => {
       let sys_obj = {}
       let dys_obj = {}
        sys_obj["x"] = val.vital_date;
        sys_obj["y"] = val.vital_reading.split(',')[0];
        sys_arr.push(sys_obj);

        dys_obj["x"] = val.vital_date;
        dys_obj["y"] = val.vital_reading.split(',')[1];
        dys_arr.push(dys_obj);


    })

    readings_arr.push(sys_arr);
    readings_arr.push(dys_arr);
    return readings_arr

  }
    let vital_reading = get_readings();
    console.log(vital_reading)
    const data = [
      {
      name:"sys",
      color: "steelblue",
      // points: [{x:10,y:80}, {x:20,y:90}]
      points: vital_reading[0]
      },
      {
      name:"dys",
      color: "steelblue",
      // points: [{x:10,y:100}, {x:20,y:120}]
      points: vital_reading[1]
      }
    ];

    return (    
        <LineChart 
        width={1150}
        height={400}
        data={data}
        showLegends ={true}
        // ticks = {20}
        isDate={true}
        xLabel={"Date"}
        yLabel={"Readings"}
        yMax={"160"}
        yMin={"20"}
        // xParser={true ? d3.timeFormat("%Y-%m-%d").parse : ((x) => x)}
        // xDisplay={true ? d3.timeFormat("%b %d") : d3.format("d")}
    />

    )
  }
}
