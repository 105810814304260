import React, { useState, useEffect } from "react";
import { Layout, Menu, Card, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import { Divider, Space } from "antd";

import { UserOutlined } from "@ant-design/icons";
import SiderComponent from "../../Components/Sider.component";

const { Header, Content } = Layout;

const Earnings = (props) => {
  const [reportsLoader, setReportsToggle] = useState(false);
  const [earningData, setEarningData] = useState([]);

  useEffect(() => {
    fetchEarningsData();
  }, []);

  const fetchEarningsData = () => {
    const url = `https://app02.thbglobal.com/v1/provider-earnings?userId=${localStorage.getItem(
      "userid"
    )}&userType=doctors`;

    fetch(url, {
      headers: {
        "x-api-key": `Bearer ${JSON.parse(localStorage.getItem("tokens"))}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.type === "Success") {
          setEarningData(result.data);
          setReportsToggle(true);
        } else {
          setReportsToggle(true);
        }
      });
  };

  return (
    <div className="health-board">
      <Layout className="layout">
        <Header>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/dashboard">Back to Dashboard</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Layout hasSider="true">
          <SiderComponent selectedKey="12" />
          <Content style={{ padding: "0 50px", marginTop: "20px" }}>
            <div className="appointments-container">
              <div className="page-title">
                <h1>My Earnings</h1>
              </div>
            </div>
            <div className="site-layout-content">
              <Divider />
              <div>
                {reportsLoader ? (
                  earningData.map((value) => (
                    <Col style={{ margin: "0 auto" }} span={8}>
                      <Card>
                        <span style={{ color: "#00008B", fontSize: "22px" }}>
                          Patient :{value.patient_name}
                        </span>
                        <br />
                        <span style={{ color: "#00008B", fontSize: "22px" }}>
                          Service : {value.service_name}, {value.service_type}{" "}
                        </span>
                        <br />
                        <span style={{ color: "#00008B", fontSize: "22px" }}>
                          Amount : {value.amount_paid}
                        </span>
                        <br />
                        <span style={{ color: "#00008B", fontSize: "22px" }}>
                          Mode : {value.payment_mode}
                        </span>
                        <br />
                        <span style={{ color: "#D3D3D3", fontSize: "22px" }}>
                          Payment Date : {value.payment_date}
                        </span>
                        <br />
                        <span style={{ color: "#D3D3D3", fontSize: "22px" }}>
                          Transaction :{value.payment_time}
                        </span>
                        <br />
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Spin
                    size="large"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </div>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "right" }}>
        </Footer>
    */}
        </Layout>
      </Layout>
    </div>
  );
};
export default Earnings;
