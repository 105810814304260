import React from 'react'
import { Table, Spin} from "antd";

const VitalsTable = ({vitalsLoader,data}) => {

    const columns = [
    
        {
          title: "Date",
          render: (record) => (
              record.vital_date
          ),
          key: "vital_date",
        },
        {
          title: "Time",
          render: (record) => record.vital_time,
          key: "vital_time",
        },
        {
          title: "Vital Reading",
          render: (record) => record.vital_reading,
          key: "vital_reading",
        },
        
        {
          title: "Notes",
          render: (record) => record.vital_notes,
          key: "vital_notes",
        }
    ]

    return (
        <div>
            {vitalsLoader ? (
                <Table  dataSource={data} columns={columns} pagination={{ position:['bottomCenter'] }} />
            ):(
                <Spin
                size="large"
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            )}
        </div>
    )
}

export default VitalsTable
