import React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

import "./temp.css";
import Barchart from "../vitals/Barchart";

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;
const data = [
  {
    uploaded_by: "Alvin",
    vital_date: "2021-07-22",
    vital_id: 80,
    vital_notes: "",
    vital_reading: "110,80",
    vital_time: "12:35 AM",
    vital_unit: "mmHg",
  },
  {
    uploaded_by: "Alvin",
    vital_date: "2021-07-23",
    vital_id: 79,
    vital_notes: "",
    vital_reading: "120,90",
    vital_time: "12:35 AM",
    vital_unit: "mmHg",
  },
];

const Temp = () => {
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]}>
          <Menu.Item key="1">nav 1</Menu.Item>
          <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            <SubMenu key="sub1" icon={<UserOutlined />} title="subnav 1">
              <Menu.Item key="1">option1</Menu.Item>
              <Menu.Item key="2">option2</Menu.Item>
              <Menu.Item key="3">option3</Menu.Item>
              <Menu.Item key="4">option4</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<LaptopOutlined />} title="subnav 2">
              <Menu.Item key="5">option5</Menu.Item>
              <Menu.Item key="6">option6</Menu.Item>
              <Menu.Item key="7">option7</Menu.Item>
              <Menu.Item key="8">option8</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<NotificationOutlined />}
              title="subnav 3"
            >
              <Menu.Item key="9">option9</Menu.Item>
              <Menu.Item key="10">option10</Menu.Item>
              <Menu.Item key="11">option11</Menu.Item>
              <Menu.Item key="12">option12</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>

        <Content
          className="site-layout-background"
          style={{
            padding: "0 50px",
            marginTop: "20px",
            height: "600px",
            background: "grey",
          }}
        >
          <Barchart data={data} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Temp;
