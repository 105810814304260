import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Layout, Select, message, Input, Image } from "antd";

import {
  ArrowLeftOutlined,
  LoadingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link, Redirect, useHistory } from "react-router-dom";
import logo from "../../Thb_Logo.jpeg";
// import { set } from "date-fns";

const { Header, Content, Footer } = Layout;
const { Option } = Select;

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [usertype, setUserType] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("One");
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  let history = useHistory();

  function requestOtp() {
    setLoading(true);
    axios
      .post("https://app02.thbglobal.com/v1/passwordReset/request", {
        user_email: email,
        user_type: usertype,
      })
      .then((result) => {
        if (result.data.type === "success") {
          message.success("OTP send to you email");
          setStep("Two");
          setLoading(false);
        } else {
          setLoading(false);
          message.error(result.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(
          "User does not exists, try again with the registered email id."
        );
      });
  }

  function submitOtp() {
    setLoading(true);
    axios
      .post("https://app02.thbglobal.com/v1/passwordReset/submit", {
        otp: otp,
        password: password,
        user_type: usertype,
      })
      .then((result) => {
        if (result.data.type === "Success") {
          message.success(result.data.type);
          setStep("Two");
          setLoading(false);
          history.push("/login");
        } else {
          message.error(result.data.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e.message);
      });
  }

  const setValue = (value) => {
    if (value == "Health Manager") {
      setUserType("healthManager");
    } else if (value === "Doctor") {
      setUserType("doctors");
    } else {
      setUserType("admin");
    }
  };

  const menu = ["Health Manager", "Doctor", "Admin"];

  return (
    <Layout>
      <Header className="Header" style={{ padding: 0 }}>
        <div
          className="site-layout-background"
          style={{
            height: 0,
            fontSize: "medium",
            paddingLeft: "10px",
            marginTop: "10px",
          }}
        >
          {/* <h1 style={{ color: "#fff" }}>THB WEB</h1> */}

          <Image
            src={logo}
            preview={false}
            style={{ height: "50px", width: "150px" }}
          />
        </div>
      </Header>
      <div className="login-container">
        <Content>
          <div className="row justify-content-center">
            <div className="card" style={{ width: "300px" }}>
              <div className="card-header">
                Reset Password
                <Link to="/login">
                  <div style={{ float: "right" }}>
                    <ArrowLeftOutlined /> Login
                  </div>
                </Link>
              </div>
              <div className="card-body">
                <Form
                  name="reset_password"
                  className="reset-form"
                  initialValues={{
                    remember: true,
                  }}
                  // onFinish={onFinish}
                >
                  {step === "One" ? (
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Email!",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        value={email}
                        style={{ width: "100%" }}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your Email"
                      />
                    </Form.Item>
                  ) : null}

                  {step === "Two" ? (
                    <>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your OTP!",
                          },
                        ]}
                      >
                        <Input
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input
                          suffix={
                            !passwordShown ? (
                              <EyeOutlined onClick={togglePassword} />
                            ) : (
                              <EyeInvisibleOutlined onClick={togglePassword} />
                            )
                          }
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : null}

                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={setValue}
                      placeholder=" User Type"
                    >
                      {/* <Option value="1">Option 1</Option> */}
                      {menu.map((val) => {
                        return (
                          <Option key={val} value={val}>
                            {val}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={step === "One" ? requestOtp : submitOtp}
                    >
                      {loading && <LoadingOutlined />}
                      {step === "One" ? "Reset Password" : "Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </div>
      <Footer></Footer>
    </Layout>
  );
};

export default PasswordReset;
